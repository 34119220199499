import {FunctionComponent, PropsWithChildren} from 'react';
import './ButtonSet.css';

const ButtonSet: FunctionComponent<PropsWithChildren> = ({children}) => {
  return (
    <div className="ButtonSet">
      {children}
    </div>
  );
}

export default ButtonSet;