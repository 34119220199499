import React, {FunctionComponent} from 'react';
import ListType from '../../types/list-type';
import Checkbox from '../form/Checkbox';
import './SubscribablesList.css';
import classNames from 'classnames';

type SubscribableListProps = {
  lists: Array<ListType>
  selected: Array<number>
  toggleAtIndex: (ix: number) => void;
};

type SubscribablesListItemProps = {
  list: ListType
  selected: boolean
  toggle: () => void
}
const SubscribablesListItem: FunctionComponent<SubscribablesListItemProps> = ({list, selected, toggle}) => {
  return (
    <li className={classNames('SubscribablesList__item', {selected})} onClick={() => {
      toggle();
    }}>
      <Checkbox checked={selected}/>
      <span className="SubscribablesList__item__label">
        {list.name}
      </span>
    </li>
  );
}

const SubscribablesList: FunctionComponent<SubscribableListProps> = ({lists, selected, toggleAtIndex}) => {
  return (
    <ul className="SubscribablesList">
      {lists.map((list, ix) => (
        <SubscribablesListItem key={list.id}
                               list={list}
                               selected={selected.indexOf(ix) >= 0}
                               toggle={() => toggleAtIndex(ix)}
        />
      ))}
    </ul>
  );
}

export default SubscribablesList;
