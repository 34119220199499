import {FunctionComponent, useEffect, useState} from 'react';
import AuthUser from '../../models/auth-user';
import FormRow from '../form/FormRow';
import TextInput from '../form/TextInput';
import Button, {ButtonType} from '../common/Button';
import api from '../../services/api';
import ErrorList from '../common/ErrorList';
import LoginValidationInput from '../form/LoginValidationInput';
import ActivityIndicator from '../common/ActivityIndicator';
import ButtonSet from '../common/ButtonSet';
import DelayedTasker from '../../utils/DelayedTasker';

type QuickRegistrationProps = {
  onAuth: (authUser: AuthUser) => void // Handle that login was successful
  authLabel?: string // Label for validation button
  onSkip?: () => void // If user is allowed to skip the login, this callback will handle the "skip"
  skipLabel?: string // What to label the "Skip" button
};

const QuickAuthUser: FunctionComponent<QuickRegistrationProps> = ({onAuth, authLabel, onSkip, skipLabel}) => {
  const [registrationCode, setRegistrationCode] = useState<string>(''); // Used when attempting to register new user
  const [validationCode, setValidationCode] = useState<string>(''); // Used when entering the confirmation code to validate a new/user
  const [authKey, setAuthKey] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [busy, setBusy] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<string>>([]);
  if (!skipLabel) skipLabel = 'Skip';
  if (!authLabel) authLabel = 'Go';

  const submissionReady = /.+@.+\..+/.test(email);

  const submitEmail = () => {
    setErrors(submissionReady ? [] : ['Invalid email']);
    if (!submissionReady) return;

    setBusy(true);
    localStorage.setItem('email', email);
    api().auth.register(email)
      .then(response => {
        setBusy(false);
        if (response.success) {
          setRegistrationCode(response.reg!);
          if (response.val) {
            setValidationCode(response.val);
          }
        } else {
          setErrors(response.errors!);
        }
      })
  };

  const submitValidation = () => {
    setErrors([]);
    setBusy(true);
    const delayedTasker = new DelayedTasker();
    api().auth.validateCode(registrationCode, validationCode)
      .then(response => {
        if (response.success) {
          if (response.user!.name.length === 0) {
            setAuthKey(response.authKey!);
            setBusy(false);
          } else {
            delayedTasker.doAfter(1000, () => {
              setBusy(false);
              onAuth(new AuthUser(response.authKey!, response.user!.name));
            });
          }
        } else {
          setBusy(false);
          setErrors(response.errors!);
          if (response.expired === true) {
            setRegistrationCode('');
            setValidationCode('');
          } else if (response.maxAttemptsExceeded === true) {
            setRegistrationCode('');
            setValidationCode('');
          }
        }
      });
  };

  useEffect(() => {
    const email = localStorage.getItem('email');
    if (email) setEmail(email);
  }, []);

  return (
    <>
      <ErrorList errors={errors}/>

      {authKey.length > 0 && (
        <>
          <FormRow>
            What is your name?
            <TextInput value={name} error={name.length <= 2} placeholder="Name" onChanged={value => setName(value)} disabled={busy}/>
          </FormRow>
          <FormRow>
            <Button type={ButtonType.Primary} disabled={busy || name.length <= 2} onClick={() => {
              setBusy(true);
              const authUser = new AuthUser(authKey, name);
              api().auth.setName(authUser, name).then(() => {
                onAuth(authUser);
              }).catch(e => {
                console.warn('Unable to set name: ' + e.message);
                onAuth(new AuthUser(authKey, ''));
              });
            }}>
              Done
            </Button>
          </FormRow>
        </>
      )}

      {authKey.length === 0 && registrationCode.length === 0 && (
        <>
          <FormRow>
            <TextInput value={email}
                       onChanged={val => setEmail(val)}
                       placeholder="Email"
                       onKeyUp={ev => {
                         if (submissionReady && ev.key === 'Enter') {
                           submitEmail();
                         }
                       }}
            />
          </FormRow>
          <FormRow>
            <div className="txt-center">
              <ButtonSet>
                <Button type={ButtonType.Primary}
                        disabled={!submissionReady}
                        onClick={() => {
                          submitEmail();
                        }}>
                  {authLabel}
                </Button>
                {onSkip && (
                  <Button type={ButtonType.Secondary} onClick={() => onSkip()}>{skipLabel}</Button>
                )}
              </ButtonSet>
            </div>
          </FormRow>
        </>
      )}

      {authKey.length === 0 && registrationCode.length > 0 && (
        <div className="txt-center">
          {busy && <FormRow><ActivityIndicator/> loading...</FormRow>}

          {!busy && (
            <>
              <FormRow label={<>Enter the validation code sent to {email} <Button type={ButtonType.Link} onClick={() => setRegistrationCode('')}>Use different email</Button></>}>
                <LoginValidationInput disabled={busy}
                                      code={validationCode}
                                      onChanged={newValidationCode => {
                                        setValidationCode(newValidationCode);
                                      }}
                                      onKeyUp={ev => {
                                        if (ev.key === 'Enter') submitValidation();
                                      }}
                />
              </FormRow>
              <FormRow>
                <Button type={ButtonType.Primary}
                        disabled={validationCode.length <= 3}
                        onClick={() => {
                          submitValidation();
                        }}>
                  Validate
                </Button>
              </FormRow>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default QuickAuthUser;
