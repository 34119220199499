import {FunctionComponent, PropsWithChildren, ReactNode} from 'react';
import './PageContainer.css';
import {LogoSize} from './PageHeader';
import Padded from '../common/Padded';
import classNames from 'classnames';

export type PageContainerProps = PropsWithChildren<{
  className?: string
  padded?: boolean
  logoSize?: LogoSize
  pageHeader?: ReactNode
}>

const PageContainer: FunctionComponent<PageContainerProps> = ({children, logoSize, className, pageHeader, padded}) => {
  return (
    <div className={classNames('PageContainer', className)}>
      {pageHeader}
      {padded === false ? children : <Padded>{children}</Padded>}
    </div>
  );
}

export default PageContainer;
