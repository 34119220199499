import {FunctionComponent, useContext, useState} from 'react';
import AccountPageContainer from '../../components/page/AccountPageContainer';
import Button, {ButtonType} from '../../components/common/Button';
import {Navigate, useNavigate} from 'react-router-dom';
import Padded from '../../components/common/Padded';
import SectionHeader from '../../components/common/SectionHeader';
import FormRow from '../../components/form/FormRow';
import TextInput from '../../components/form/TextInput';
import ErrorList from '../../components/common/ErrorList';
import AppContext from '../../contexts/app-context';
import QuickAuthUser from '../../components/auth/QuickAuthUser';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import api from '../../services/api';
import ButtonSet from '../../components/common/ButtonSet';
import {LogoSize} from '../../components/page/PageHeader';

const CreateMicrositePage: FunctionComponent = () => {
  const [busy, setBusy] = useState<boolean>(false);
  const [slug, setSlug] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [errors, setErrors] = useState<Array<string>>([]);

  const onUrlChanged = (val: string) => {
    if (val.match(/[^a-zA-Z0-9 -]/)) return false;
    val = val.replace(/ +/, '-');
    val = val.replace(/-{2,}/, '-');
    setSlug(val);
  };

  const appContext = useContext(AppContext);
  const nav = useNavigate();
  if (!appContext.authUser) return <Navigate to="/" />;

  return (
    <AccountPageContainer padded={false} logoSize={LogoSize.Normal}>
      <ErrorList errors={errors}/>

      {appContext.authUser === null && (
        <Padded>
          <div className="txt-center">
            Already a user?&nbsp;
            <Button type={ButtonType.Link} onClick={() => {
              nav('/login');
            }}>
              Login instead
            </Button>
          </div>

          {/*<SectionHeader>Account</SectionHeader>*/}
          <div className="m-t">
            <QuickAuthUser onAuth={authUser => {
              appContext.setAuthUser(authUser);
              nav('/');
            }}/>
          </div>
        </Padded>
      )}

      {appContext.authUser !== null && (
        <>
          <SectionHeader>Register</SectionHeader>
          <Padded>
            <FormRow label="URL:">
              <div style={{display: 'flex', alignItems: 'center', marginTop: 5}}>
                <div style={{flexGrow: 1}}>
                  <TextInput value={slug} onChanged={onUrlChanged}/>
                </div>
                <div>.followthecraft.com</div>
              </div>
            </FormRow>

            <FormRow label="Name or company that users will see:">
              <TextInput value={name}
                         onChanged={val => setName(val)}
                         placeholder="Name"
              />
            </FormRow>

            <FormRow>
              {busy === true && <><ActivityIndicator/> Creating</>}
              {/*<Button type={ButtonType.Primary} onClick={() => setBusy(false)}>Reset</Button>*/}
              {busy === false && (
                <ButtonSet>
                  <Button type={ButtonType.Primary} onClick={() => {
                    setBusy(true);
                    api().microsite.create(appContext.authUser!, slug, name)
                      .then(response => {
                        if (response.success) {
                          nav('/manage');
                        } else {
                          setBusy(false);
                          setErrors(response.errors);
                        }
                      })
                      .catch(() => {
                        setBusy(false);
                        setErrors(['An unknown error occurred'])
                      });
                  }}>
                    Register
                  </Button>
                  <Button type={ButtonType.Secondary} onClick={() => nav('/manage')}>Cancel</Button>
                </ButtonSet>
              )}
            </FormRow>
          </Padded>
        </>
      )}
    </AccountPageContainer>
  );
}

export default CreateMicrositePage;
