/**
 * Convenience utility that will return a promise whether the argument is a promise or not.
 * @param {Promise} promiseOrNot
 */
const ensurePromise = (promiseOrNot: Promise<void> | void): Promise<void> => {
  if (promiseOrNot === undefined) return Promise.resolve();
  else return promiseOrNot;
}

export {ensurePromise}
