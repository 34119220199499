export enum ActionStatus {
  Error,
  Normal,
  Busy
}

type ActionState = {
  current: ActionStatus
  errors: Array<string>
}

export default ActionState
