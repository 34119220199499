class CachedObjects<T> {
  private data: { [key: string]: T } = {}

  public has(key: string): boolean {
    return this.data[key] !== undefined;
  }

  public get(key: string): T | undefined {
    return this.data[key];
  }

  public set(key: string, val: T) {
    this.data[key] = val;
  }

  public del(key: string) {
    if (this.has(key)) delete this.data[key];
  }
}

export default CachedObjects;
