import {FunctionComponent, PropsWithChildren} from 'react';
import Padded from './Padded';

type SectionHeaderProps = PropsWithChildren;

const SectionHeader: FunctionComponent<SectionHeaderProps> = ({children}) => {
  return (
    <div style={{backgroundColor: '#ed6a46', color: '#fff'}}>
      <Padded>
        <h1 className="txt-center" style={{margin: 0, fontSize: '1.4rem'}}>{children}</h1>
      </Padded>
    </div>
  );
}

export default SectionHeader;