import CachedObjects from '../utils/cached-objects';
import MicrositeType from '../types/microsite-type';
import MicrositeApi from './api/microsite-api';
import AuthUser from '../models/auth-user';
import ListApi from './api/list-api';
import ListType from '../types/list-type';
import SubscribableResult from '../models/subscribable-result';
import ResponseErrors from '../models/ResponseErrors';
import AddListType from '../types/add-list-type';
import AssociateListResponseType from '../types/link-list-response-type';
import SuccessOrFailResponseType from '../types/success-or-fail-response-type';

/**
 * @TODO How to handle DELETE microsite and notify subscribers
 */
class MicrositeService {
  private static _instance: MicrositeService | undefined;

  private cacheSlugs = new CachedObjects<SubscribableResult<MicrositeType>>();
  private managedMicrositeIds = new CachedObjects<boolean>();
  private managedListIds = new CachedObjects<boolean>();
  private subscribedListIds = new CachedObjects<boolean>();

  public constructor(private micrositeApi: MicrositeApi, private listApi: ListApi) {
  }

  public get(slug: string, authUser: (AuthUser | null) = null): Promise<SubscribableResult<MicrositeType>> {
    if (this.cacheSlugs.has(slug)) return Promise.resolve(this.cacheSlugs.get(slug)!);

    return this.refresh(slug, authUser);
  }

  private refresh(slug: string, authUser: (AuthUser | null) = null): Promise<SubscribableResult<MicrositeType>> {
    return this.micrositeApi.getMicrosite(slug, authUser).then(response => {
      this.cacheManagedMicrosites([response.microsite], response.managedMicrosites);
      this.cacheManagedLists(response.microsite.lists, response.managedLists);
      this.cacheSubscribedLists(response.microsite.lists, response.subscribedLists);

      return this.cacheMicrosite(response.microsite);
    });
  }

  public create(authUser: AuthUser, slug: string, name: string): Promise<SubscribableResult<MicrositeType>> {
    return this.micrositeApi.create(authUser, slug, name).then(response => {
      // Let the world know we're terrible people.  Or maybe they're terrible people
      if (!response.success) {
        throw new ResponseErrors(response.errors);
      }

      return this.cacheMicrosite(response.microsite);
    });
  }

  public save(authUser: AuthUser, microsite: MicrositeType): Promise<SubscribableResult<MicrositeType>> {
    return this.micrositeApi.save(authUser, microsite).then(response => {
      // Let the world know we failed
      if (!response.success) {
        throw new ResponseErrors(response.errors);
      }

      return this.cacheMicrosite(response.microsite);
    });
  }

  public addList(authUser: AuthUser, slug: string, list: AddListType): Promise<ListType> {
    return this.listApi.create(authUser, list, slug).then(response => {
      if (!response.success) {
        throw new ResponseErrors(response.errors);
      }

      return this.refresh(slug, authUser)
        .then(() => response.list);
    });
  }

  public saveList(authUser: AuthUser, slug: string, list: ListType): Promise<ListType>
  {
    return this.listApi.save(authUser, list).then(response => {
      if (!response.success) {
        throw new ResponseErrors(response.errors);
      }
      return this.refresh(slug, authUser)
        .then(() => response.list);
    });
  }

  public associateList(authUser: AuthUser, slug: string, listId: number): Promise<AssociateListResponseType> {
    return this.micrositeApi.associateList(authUser, slug, listId).then(response => {
      if (!response.success) {
        throw new ResponseErrors(response.errors);
      }

      return this.refresh(slug, authUser).then(() => response);
    });
  }

  public disassociateList(authUser: AuthUser, slug: string, listId: number): Promise<void> {
    return this.micrositeApi.disassociateList(authUser, slug, listId).then(response => {
      if (!response.success) {
        throw new ResponseErrors(response.errors);
      }

      return this.refresh(slug, authUser).then(() => {});
    });
  }

  public archiveList(authUser: AuthUser, slug: string, listId: number): Promise<void> {
    return this.micrositeApi.archiveList(authUser, slug, listId).then(response => {
      if (!response.success) {
        throw new ResponseErrors(response.errors);
      }

      return this.refresh(slug, authUser).then(() => {});
    });
  }

  public voteForList(slug: string, listId: number): Promise<SuccessOrFailResponseType> {
    return this.micrositeApi.voteForList(slug, listId);
  }

  /**
   * Cache microsite
   * @param microsite
   * @private
   */
  private cacheMicrosite(microsite: MicrositeType): SubscribableResult<MicrositeType> {
    let resultSubscription: SubscribableResult<MicrositeType> | undefined = this.cacheSlugs.get(microsite.slug);
    if (resultSubscription === undefined) {
      resultSubscription = new SubscribableResult<MicrositeType>(microsite);
      this.cacheSlugs.set(microsite.slug, resultSubscription);
    } else {
      resultSubscription.result = microsite;
    }

    return resultSubscription;
  }

  /**
   * Keep track of which microsites the user manages
   * @param managedSiteIds
   * @private
   */
  private cacheManagedMicrosites(microsites: Array<MicrositeType>, managedSiteIds: Array<number>) {
    microsites.forEach(microsite => {
      const isManager = managedSiteIds.indexOf(microsite.id) >= 0;
      this.managedMicrositeIds.set(microsite.id.toString(), isManager);
    });
  }

  /**
   * Keep track of which lists the user manages
   * @param lists
   * @param managedListIds
   * @private
   */
  private cacheManagedLists(lists: Array<ListType>, managedListIds: Array<number>) {
    lists.forEach(list => {
      const doesManage = managedListIds.indexOf(list.id) >= 0;
      this.managedListIds.set(list.id.toString(), doesManage);
    });
  }

  /**
   * Keep track of which lists the user is subscribed to
   * @param lists
   * @param subscribedListIds
   * @private
   */
  private cacheSubscribedLists(lists: Array<ListType>, subscribedListIds: Array<number>) {
    lists.forEach(list => {
      const isSubscribed = subscribedListIds.indexOf(list.id) >= 0;
      this.subscribedListIds.set(list.id.toString(), isSubscribed);
    });
  }

  public isMicrositeManager(authUser: AuthUser, siteId: number): boolean {
    return this.managedMicrositeIds.get(siteId.toString()) === true;
  }

  public isListManager(authUser: AuthUser, listId: number): boolean {
    return this.managedListIds.get(listId.toString()) === true;
  }

  public isSubscribed(authUser: AuthUser, listId: number): boolean {
    return this.subscribedListIds.get(listId.toString()) === true;
  }

  public subscribe(authUser: AuthUser, listId: number): Promise<boolean> {
    return this.listApi.subscribe(authUser, listId).then(response => {
      this.subscribedListIds.set(listId.toString(), true);
      return response;
    });
  }

  public unsubscribe(authUser: AuthUser, listId: number): Promise<boolean> {
    return this.listApi.unsubscribe(authUser, listId).then(response => {
      this.subscribedListIds.set(listId.toString(), false);
      return response;
    });
  }

  public synchronizeSubscription(authUser: AuthUser, slug: string, listIds: Array<number>): Promise<void> {
    return this.micrositeApi.synchronizeSubscription(authUser, slug, listIds);
  }
}

export default MicrositeService
