import {FunctionComponent, useContext, useEffect, useState} from 'react';
import AccountPageContainer from '../../components/page/AccountPageContainer';
import {LogoSize} from '../../components/page/PageHeader';
import SectionHeader from '../../components/common/SectionHeader';
import Padded from '../../components/common/Padded';
import MicrositeType from '../../types/microsite-type';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import api from '../../services/api';
import AppContext from '../../contexts/app-context';
import {useNavigate} from 'react-router-dom';
import MicrositeList from '../../components/account/AccountMicrositeList';
import Button, {ButtonType} from '../../components/common/Button';
import DelayedTasker from '../../utils/DelayedTasker';

const AccountHomePage: FunctionComponent = () => {
  const [microsites, setMicrosites] = useState<Array<MicrositeType> | undefined>();
  const [selectedMicrositeIx, setSelectedMicrositeIx] = useState<number | undefined>();

  const appContext = useContext(AppContext);
  const nav = useNavigate();

  if (!appContext.authUser) nav('/welcome');

  useEffect(() => {
    api().microsite.getMicrosites(appContext.authUser!)
      .then(response => {
        setMicrosites(response.microsites);
      })
      .catch(e => {

      });
  }, [appContext.authUser]);

  useEffect(() => {
    if (microsites === undefined || selectedMicrositeIx === undefined || appContext.authUser === null) return;
    const delayedTasker = new DelayedTasker();
    // console.log('Preparing transfer');
    api().auth.handoff(appContext.authUser).then(handoff => {
      const handoffUrl = microsites[selectedMicrositeIx].url + '/handoff/' + handoff;
      // console.log('Handing off: ', handoffUrl);
      window.open(handoffUrl, '_blank');
      delayedTasker.doAfter(2000, () => {
        setSelectedMicrositeIx(undefined);
      });
    });
  }, [microsites, selectedMicrositeIx, appContext.authUser]);

  return (
    <AccountPageContainer logoSize={LogoSize.Normal} padded={false}>
      <SectionHeader>Manage</SectionHeader>
      <Padded>
        {microsites === undefined && <><ActivityIndicator/> Loading</>}
        {microsites !== undefined && (
          selectedMicrositeIx === undefined ? (
            <>
              <div className="m-b">
                <MicrositeList microsites={microsites}
                               onClickAtIndex={ix => {
                                 setSelectedMicrositeIx(ix);
                               }}
                />
              </div>
              <div className="m-b">
                <Button type={ButtonType.Primary} onClick={() => {
                  nav('/register');
                }}>
                  Create New Microsite
                </Button>
              </div>
            </>
          ) : (
            <div className="txt-center">
              <ActivityIndicator/> preparing for warp speed
            </div>
          )
        )}
      </Padded>
    </AccountPageContainer>
  );
}

export default AccountHomePage;
