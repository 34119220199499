import BaseApi from './base-api';
import AuthUser from '../../models/auth-user';

type RegistrationData = {
  email: string
};

type RegistrationResponse = {
  success: true
  errors?: Array<string>
  reg?: string
  val?: string // Only possibly set in DEV environments to AUTO LOGIN
};

type ValidationResponse = {
  success: boolean
  errors?: Array<string>
  maxAttemptsExceeded?: boolean
  expired?: boolean
  authKey?: string
  expiration?: number
  user?: {
    name: string
  }
}

type AuthStatusResponse = {
  success: boolean
  authKey: string
  user: {
    name: string
  }
};

class AuthApi extends BaseApi {
  public status(authCode: string): Promise<AuthUser | null> {
    const config = this.authKeyConfig(authCode);

    return this.apiClient.get<AuthStatusResponse>('/auth/status', config)
      .then(response => {
        if (response.data.success) {
          return new AuthUser(response.data.authKey, response.data.user.name);
        }

        return null;
      });
  }

  public renew(authCode: string): Promise<AuthUser | null> {
    return this.apiClient.get<AuthStatusResponse>('/auth/renew', this.authKeyConfig(authCode))
      .then(response => {
        if (response.data.success) {
          return new AuthUser(response.data.authKey, response.data.user.name);
        }

        return null;
      });
  }

  public validateHandoff(authCode: string): Promise<AuthUser | null> {
    return this.status('handoff:'+authCode);
  }

  /**
   * Get a code that can be used to log the user into another API-based site, e.g. microsite
   * @param authUser
   */
  public handoff(authUser: AuthUser): Promise<string> {
    return this.apiClient.get<{
      success: boolean,
      errors: Array<string>,
      handoff: string
    }>('/auth/handoff', this.authConfig(authUser))
      .then(response => {
        if (response.data.success) return response.data.handoff;

        throw new Error('Unable to obtain transfer authorization');
      });
  }

  public register(email: string): Promise<RegistrationResponse> {
    return this.apiClient.post<RegistrationResponse, RegistrationData>('/auth/code', {
      email
    }).then(response => {
      return response.data;
    });
  }

  public validateCode(registrationCode: string, validationCode: string) {
    return this.apiClient.post<ValidationResponse, { reg: string, val: string }>('/auth/code/validate', {
      reg: registrationCode,
      val: validationCode
    }).then(response => response.data);
  }
}

export default AuthApi;
