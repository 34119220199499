import React, {FunctionComponent, useContext, useState} from 'react';
import {LogoSize} from '../../components/page/PageHeader';
import MicrositePageContainer from '../../components/page/MicrositePageContainer';
import MicrositeContext from '../../contexts/microsite-context';
import Padded from '../../components/common/Padded';
import SubscribablesList from '../../components/microsite/SubscribablesList';
import {listTypeLabels} from '../../constants/list-type-labels';
import Checkbox from '../../components/form/Checkbox';
import ButtonSet from '../../components/common/ButtonSet';
import Button, {ButtonType} from '../../components/common/Button';
import ErrorList from '../../components/common/ErrorList';
import './VotePage.css';

type VotePageProps = {};

enum VoteStatus {
  None, // Default date
  Submitting, // Submitting to server
  Done, // Successfully submitted
}

const VotePage: FunctionComponent<VotePageProps> = () => {
  const {microsite, service} = useContext(MicrositeContext);
  const [selectedListIx, setSelectedListIx] = useState<number | undefined>(undefined);
  const [errors, setErrors] = useState<Array<string>>([]);
  const [voteStatus, setVoteStatus] = useState<VoteStatus>(VoteStatus.None);

  return (
    <MicrositePageContainer padded={false} logoSize={LogoSize.Small} className="PageContainer--voter">
      <Padded>
        <ErrorList errors={errors}/>

        <h1 className="txt-center" style={{fontWeight: 'normal'}}>
          {microsite.name}
        </h1>

        {selectedListIx === undefined && (
          <h2 className="txt-center" style={{padding: '50px 10px'}}>
            <Checkbox checked={true}/> Vote for your favorite {listTypeLabels.movie.singular.toLowerCase()}
          </h2>
        )}

        {microsite.lists.length === 0 && <p>There are not currently any lists associated with this link.</p>}

        {selectedListIx !== undefined && (
          <div className="txt-center">
            {voteStatus === VoteStatus.Done && <p>Thank you for voting!</p>}

            {voteStatus === VoteStatus.Submitting && <p>Submitting...</p>}

            {voteStatus === VoteStatus.None && (
              <>
                <p>Lock in your vote for<br/><strong>{microsite.lists[selectedListIx].name}</strong>?</p>
                <ButtonSet>
                  <Button type={ButtonType.Success}
                          onClick={() => {
                            setVoteStatus(VoteStatus.Submitting);
                            setErrors([]);
                            service.voteForList(microsite.slug, microsite.lists[selectedListIx].id).then(response => {
                              if (response.success) {
                                setVoteStatus(VoteStatus.Done);
                                window.setTimeout(() => {
                                  const storage = window.localStorage;
                                  storage.setItem('voted', 'true');
                                  storage.setItem('votedList', microsite.lists[selectedListIx].id.toString());
                                  storage.setItem('votedDate', (new Date()).getTime().toString());
                                  window.location.reload();
                                }, 2000);

                              } else {
                                setErrors(response.errors);
                                setVoteStatus(VoteStatus.None);
                              }
                            })
                              .catch(() => {
                                setErrors(['An unknown error occurred.']);
                                setVoteStatus(VoteStatus.None);
                              });
                          }}>Yes</Button>
                  <Button type={ButtonType.Danger} onClick={() => setSelectedListIx(undefined)}>Change</Button>
                </ButtonSet>
                </>
            )}
          </div>
        )}

        {microsite.lists.length > 0 && selectedListIx === undefined && (
          <>
            <p className="txt-center">Select your favorite:</p>

            <SubscribablesList lists={microsite.lists}
                               selected={selectedListIx !== undefined ? [selectedListIx] : []}
                               toggleAtIndex={ix => {
                                 setSelectedListIx(ix);
                               }}
            />
          </>
        )}
      </Padded>
    </MicrositePageContainer>
  );
}

export default VotePage;