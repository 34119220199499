import {FunctionComponent, PropsWithChildren} from 'react';
import './Padded.css';

const Padded: FunctionComponent<PropsWithChildren> = ({children}) => {
  return (
    <div className="Padded">
      {children}
    </div>
  );
}

export default Padded;