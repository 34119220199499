import {FunctionComponent, useContext, useState} from 'react';
import QRCode from 'react-qr-code';
import MicrositeContext from '../../contexts/microsite-context';
import Button, {ButtonType} from '../../components/common/Button';
import {useNavigate} from 'react-router-dom';
import FormRow from '../../components/form/FormRow';
import MicrositePageContainer from '../../components/page/MicrositePageContainer';
import AppContext from '../../contexts/app-context';
import {LogoSize} from '../../components/page/PageHeader';
import './MicrositeHome.css';

const MicrositeHome: FunctionComponent = () => {
  const [showDownloadable, setShowDownloadable] = useState<boolean>(false);
  const qrWidth = 256;
  const registerUrl = window.location.protocol + '//' + window.location.host + '/subscribe';
  const nav = useNavigate();
  const qrCodeImage = 'https://chart.googleapis.com/chart?cht=qr&chs=256&chl=' + encodeURI(registerUrl);
  const micrositeContext = useContext(MicrositeContext);
  const {name, id: micrositeId} = micrositeContext.microsite;
  const micrositeService = micrositeContext.service;
  const authUser = useContext(AppContext).authUser;

  return (
    <MicrositePageContainer logoSize={LogoSize.Small}>
      {name && (
        <h1 className="txt-center" style={{fontWeight: 'normal'}}>
          {name}
        </h1>
      )}
      {authUser !== null && micrositeService.isMicrositeManager(authUser, micrositeId) && (
        <div className="txt-center">
          <Button type={ButtonType.Primary} onClick={() => nav('/manage')}>Manage</Button>
        </div>
      )}

      <>
        {!showDownloadable && (
          <>
            <div className="txt-center m-t">
              Scan to Subscribe
            </div>
            <div className="m-t" style={{height: "auto", marginLeft: 'auto', marginRight: 'auto', maxWidth: qrWidth, width: "100%"}}>
              <Button type={ButtonType.Link} onClick={() => nav('/subscribe')}>
                <QRCode
                  size={qrWidth}
                  className="pulsing-qr-code"
                  style={{height: "auto", maxWidth: "100%", width: "100%"}}
                  value={registerUrl}
                />
              </Button>
            </div>
            <div className="txt-center m-t">
              <FormRow>
                <Button type={ButtonType.Link}
                        onClick={() => setShowDownloadable(true)}>
                  View Downloadable Version
                </Button>
              </FormRow>
              <FormRow>
                <Button type={ButtonType.Link}
                        onClick={() => nav('/subscribe')}
                >
                  Subscribe
                </Button>
              </FormRow>
            </div>
          </>
        )}
        {showDownloadable && (
          <div className="txt-center">
            <p><img src={qrCodeImage} width={256} alt="QR Code"/></p>
            <p>Right click (or press and hold on mobile) to save</p>
            <FormRow>
              <Button type={ButtonType.Link}
                      onClick={() => setShowDownloadable(false)}>
                Back to standard version
              </Button>
            </FormRow>
          </div>
        )}
      </>
    </MicrositePageContainer>
  );
}

export default MicrositeHome;
