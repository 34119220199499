import {FunctionComponent, PropsWithChildren, ReactNode} from 'react';
import './FormRow.css';

type FormRowProps = PropsWithChildren<{
  label?: ReactNode
}>;

const FormRow: FunctionComponent<FormRowProps> = ({children, label}) => {
  return (
    <div className="FormRow">
      {label !== undefined && (
        <div className="FormRow__label">{label}</div>
      )}
      {children}
    </div>
  );
}

export default FormRow;
