import {FunctionComponent, PropsWithChildren} from 'react';
import './Button.css';
import classNames from 'classnames';

export enum ButtonType {
  Default = 'default',
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Success = 'success',
  Link = 'link',
  DangerLink = 'danger-link',
  Danger = 'danger'
}
type ButtonProps = PropsWithChildren<{
  disabled?: boolean
  onClick?: () => void
  type?: ButtonType
}>;

const Button: FunctionComponent<ButtonProps> = ({children, disabled, onClick, type}) => {
  if (!type) type = ButtonType.Default;

  return (
    <div className={classNames('Button', 'Button--' + type, {disabled})}
         onClick={() => {
           if (!disabled && onClick) onClick();
         }}>
      {children}
    </div>
  );
}

export default Button;
