import ListLinkType from '../types/list-link-type';
import {ReactNode} from 'react';
import linkTypes from '../config/link-types';

export type LinkTypeValidator = {
  type: string
  subtype?: string
  label: string
  examples: Array<string>
  validFormats: Array<RegExp>
  icon: string
  display?: (link: ListLinkType) => ReactNode
  linkHref?: (link: ListLinkType) => string
  validationFormatter?: (value: string) => string // Format the value that is use to check against validForms
  validationOrder?: number // The order in which links will be checked to determine type
}

class LinkTypeService {
  private readonly _linkTypes: Array<LinkTypeValidator> = [];
  private linkTypeMap: { [type: string]: number } = {}; // Map "type" => ix // validators[ix]
  private aliases: { [type: string]: string } = {};
  private DEFAULT_KEY = '__DEFAULT__';

  public register(linkType: LinkTypeValidator) {
    const key = this.linkKey(linkType.type, linkType.subtype);
    this.linkTypeMap[key] = this._linkTypes.length;
    this._linkTypes.push(linkType);
  }

  private linkKey(type: string, subtype?: string)
  {
    let key = type;
    if (subtype !== undefined && subtype.length > 0) key += '--' + subtype;
    return key;
  }

  get linkTypes(): Array<LinkTypeValidator> {
    return this._linkTypes;
  }

  get default(): LinkTypeValidator {
    if (this.aliases[this.DEFAULT_KEY] === undefined) throw new Error('No default link type');
    else if (this.linkTypeMap[this.aliases[this.DEFAULT_KEY]]) throw new Error('Default link type references invalid link type');

    return this.linkTypes[this.linkTypeMap[this.aliases[this.DEFAULT_KEY]]];
  }

  public setDefaultType(linkType: string): void {
    this.aliases[this.DEFAULT_KEY] = linkType;
  }

  private alias(type: string, refType: string) {
    if (this.linkTypeMap[type] !== undefined) throw new Error('Cannot create alias for types that already exists');
    this.aliases[type] = refType;
  }

  linkType(type: string, subtype?: string): LinkTypeValidator | undefined {
    const key = this.linkKey(type, subtype);
    if (this.linkTypeMap[key] === undefined) return undefined;

    return this.linkTypes[this.linkTypeMap[key]];
  }

  public guessAddressType(address: string): LinkTypeValidator | undefined {
    const linkTypes = [...this.linkTypes].sort((a, b) => {
      if (a.validationOrder === undefined && b.validationOrder !== undefined) {
        // console.log('A:', a.label, a.validationOrder, 'B:', b.label, b.validationOrder, 'Choose B');
        return -1;
      } else if (a.validationOrder !== undefined && b.validationOrder === undefined) {
        // console.log('A:', a.label, a.validationOrder, 'B:', b.label, b.validationOrder, 'Choose A');
        return 1;
      } else if (a.validationOrder !== undefined && b.validationOrder !== undefined) {
        // console.log('A:', a.label, a.validationOrder, 'B:', b.label, b.validationOrder, 'Choose C');
        if (a.validationOrder <= b.validationOrder) return -1;
        else return 1;
      }

      // console.log('A:', a.label, a.validationOrder, 'B:', b.label, b.validationOrder, 'Same');
      return 0;
    });
    // console.log('Link types:', linkTypes);
    // Go through each link type and test to see if the address matches one of its validFormats
    for (let i = 0, j = linkTypes.length; i < j; i++) {
      for (let x=0, y= linkTypes[i].validFormats.length; x < y; x++) {
        let testAddress = linkTypes[i].validationFormatter === undefined ? address : linkTypes[i].validationFormatter!(address);

        if (linkTypes[i].validFormats[x].test(testAddress)) {
          return linkTypes[i];
        }
      }
    }

    // No matches
    return undefined;
  }

  linkHref(link: ListLinkType): string
  {
    const linkType = this.linkType(link.type, link.subtype) ?? this.default;
    return linkType.linkHref === undefined ? link.address : linkType.linkHref(link);
  }
}

class LinkTypeServiceFactory {
  public static create(): LinkTypeService {
    const service = new LinkTypeService();
    linkTypes.types.forEach(linkType => {
      service.register(linkType);
    });
    service.setDefaultType(linkTypes.default);

    return service;
  }
}

export default LinkTypeService;
export {LinkTypeServiceFactory};
