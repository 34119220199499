import {FunctionComponent, useState} from 'react';
import TextInput from '../form/TextInput';
import Button, {ButtonType} from '../common/Button';
import Module, {ModuleFooter} from '../common/Module';
import LinkList from '../lists/LinkList';
import update from 'immutability-helper';
import AddListType from '../../types/add-list-type';

type AddListProps = {
  onAdd: (list: AddListType) => void
  onCancel: () => void
};

enum AddListStep {
  Name,
  Links,
}

const AddList: FunctionComponent<AddListProps> = ({onAdd, onCancel}) => {
  const [step, setStep] = useState<AddListStep>(AddListStep.Name);
  const [list, setList] = useState<AddListType>({
    name: '',
    links: []
  });

  let helpText = '';
  let btnPrevLabel = 'Previous';
  let btnPrevVisible = true;
  let btnNextLabel = 'Continue';
  let btnNextVisible = true;
  let btnNextDisabled = false;
  let btnPrevAction = () => {
  };
  let btnNextAction = () => {
  }

  switch (step) {
    case AddListStep.Name:
      btnNextDisabled = list.name.length === 0;
      btnPrevAction = () => onCancel();
      btnNextAction = () => setStep(AddListStep.Links);
      btnPrevLabel = 'Cancel';
      break;
    case AddListStep.Links:
      if (list.links.length === 0) {
        btnNextDisabled = true;
        helpText = 'Add one or more links to continue.';
      } else {
        btnPrevAction = () => setStep(AddListStep.Name);
        btnNextAction = () => {
          onAdd(list);
        };
        btnNextLabel = 'Create';
      }
      break;
  }

  return (
    <Module>
      {step === AddListStep.Name && <div><strong>Add Contact List</strong></div>}
      {step !== AddListStep.Name && <h2 className="txt-center">{list.name}</h2>}
      {/*{step === AddListStep.Name && <div><strong>Add Contact List</strong></div>}*/}
      {/*{step !== AddListStep.Name && <Module.Header title={list.name}/>}*/}


      {step === AddListStep.Name && (
        <div className="m-t">
          <TextInput value={list.name}
                     onChanged={val => setList(update(list, {name: {$set: val}}))}
                     placeholder="List Name:"
                     onKeyUp={e => {
                       if (e.key === 'Enter' && list.name.length > 0) {
                         btnNextAction();
                       }
                     }}
          />
        </div>
      )}

      {step === AddListStep.Links && (
        <LinkList links={list.links}
                  onChange={links => {
                    setList(update(list, {
                      links: {$set: links}
                    }))
                  }}/>
      )}

      {helpText.length > 0 && <p className="txt-center txt-italic txt-danger">{helpText}</p>}

      {(btnPrevVisible || btnNextVisible) && (
        <ModuleFooter>
          <div className="flex">
            <div className="flex-grow">
              {btnPrevVisible && (
                <Button type={ButtonType.Secondary}
                        onClick={() => btnPrevAction()}
                >
                  {btnPrevLabel}
                </Button>
              )}
            </div>
            <div>
              {btnNextVisible && (
                <Button type={ButtonType.Primary}
                        disabled={btnNextDisabled}
                        onClick={() => btnNextAction()}
                >
                  {btnNextLabel}
                </Button>
              )}
            </div>
          </div>
        </ModuleFooter>
      )}
    </Module>
  );
}

export default AddList;
