import {FunctionComponent, useContext} from 'react';
import Button, {ButtonType} from '../../components/common/Button';
import {useNavigate} from 'react-router-dom';
import AccountPageContainer from '../../components/page/AccountPageContainer';
import QuickAuthUser from '../../components/auth/QuickAuthUser';
import AppContext from '../../contexts/app-context';

const LoginPage: FunctionComponent = () => {
  const nav = useNavigate();
  const appContext = useContext(AppContext);
  if (appContext.authUser) nav('/');

  return (
    <AccountPageContainer>
      <div className="txt-center">
        Not a user? <Button type={ButtonType.Link} onClick={() => {
        nav('/register');
      }}>Register instead</Button>
      </div>
      <div className="m-t">
        <QuickAuthUser onAuth={authUser => {
          appContext.setAuthUser(authUser);
          nav('/');
        }}/>
      </div>
    </AccountPageContainer>
  );
}

export default LoginPage;
