import {forwardRef, MouseEvent} from 'react';
import './HamburgerButton.css';

type HamburgerButtonProps = {
  onClick: (ev: MouseEvent<HTMLDivElement>) => void
};

const HamburgerButton = forwardRef<HTMLDivElement, HamburgerButtonProps>(
  ({onClick}, ref) => {
    return (
      <div className="HamburgerButton" onClick={ev => onClick(ev)} ref={ref}/>
    );
  }
)

export default HamburgerButton;