import {FunctionComponent, useContext} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import WelcomePage from './WelcomePage';
import CreateMicrositePage from './CreateMicrositePage';
import LoginPage from './LoginPage';
import AccountHomePage from './AccountHomePage';
import AppContext from '../../contexts/app-context';

const AccountIndex: FunctionComponent = () => {
  const {authUser} = useContext(AppContext);
  return (
    <Routes>
      {authUser && (
        <>
          <Route path="/manage" element={<AccountHomePage />}/>
          {/*<Route path="/manage/approvals" element={<ApprovalPage />} />*/}
          <Route index element={<Navigate to="/manage"/>} />
        </>
      )}
      {!authUser && (
        <>
          <Route index element={<WelcomePage/>}/>
          <Route path="/manage" element={<Navigate to="/" />}/>
        </>
      )}
      <Route path="/register" element={<CreateMicrositePage/>}/>
      <Route path="/login" element={<LoginPage/>}/>
    </Routes>
  // <SectionHeader>
  //   What is Follow the Craft?
  // </SectionHeader>
  //
  // <Padded>
  //   Follow the Craft is a service that makes it easy for your fans to share their email address with you.
  // </Padded>
  //
  // <SectionHeader>
  //   How does it work?
  // </SectionHeader>
  //
  // <Padded>
  //   <p>Follow the Craft provides you with a unique QR code.</p>
  //
  //   <p>Your fans scan the QR and enter their email address.</p>
  //
  //   <p>You receive a copy of the email address to that you can build your fan base.</p>
  // </Padded>
  )
}

export default AccountIndex;
