import {FunctionComponent, PropsWithChildren} from 'react';
import './ListView.css';
import classNames from 'classnames';

type ListViewProps = PropsWithChildren;
type ListViewItemProps = PropsWithChildren<{onClick?: () => void}>

type ListViewComponent = FunctionComponent<ListViewProps> & {
  Item: FunctionComponent<ListViewItemProps>
}

const ListView: ListViewComponent = ({children}) => {
  return (
    <div className="ListView">{children}</div>
  );
}

ListView.Item = ({children, onClick}) => {
  return (
    <div className={classNames('ListView__item', {clickable: onClick !== undefined})}
         onClick={() => {
           if (onClick) onClick();
         }}
    >
      {children}
    </div>
  )
}

export default ListView;