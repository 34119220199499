import {FunctionComponent} from 'react';
import PageContainer, {PageContainerProps} from './PageContainer';
import PageHeader from './PageHeader';

type AccountPageContainerProps = PageContainerProps & {};

const AccountPageContainer: FunctionComponent<AccountPageContainerProps> = (props) => {
  return (
    <PageContainer {...props}
      pageHeader={<PageHeader logoSize={props.logoSize}/>}
    />
  );
}

export default AccountPageContainer;
