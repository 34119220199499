import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';

export default class APIClient {
  private axios: AxiosInstance;

  constructor(private urlBase: string) {
    this.axios = axios.create({
      baseURL: urlBase
    });
  }

  config(config?: AxiosRequestConfig): AxiosRequestConfig {
    if (!config) config = {} as AxiosRequestConfig;
    if (!config.headers) config.headers = {};
    if (!config.headers['Access-Control-Allow-Origin']) config.headers['Access-Control-Allow-Origin'] = '*';
    if (!config.headers['Access-Control-Allow-Methods']) config.headers['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';

    return config;
  }

  public get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axios.get<T, R>(url, config);
  }

  public post<T, B = T, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    // post<T = any, R = axios.AxiosResponse<T>, D = any>(url: string, data?: D, config?: axios.AxiosRequestConfig<D>): Promise<R>;
    return this.axios.post<T, R, B>(url, data, config);
  }

  // public delete<T, B = T, R = AxiosResponse<T>>(
  public delete<T, B=T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    // delete<T = any, R = AxiosResponse<T>, D = any>
    return this.axios.delete<T, R, B>(url, config);
  }

  public put<T, B = T, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig,
  ): Promise<R> {
    return this.axios.put<T, R, B>(url, data, config);
  }

  private enableDebug() {
    this.axios.interceptors.request.use(request => {
      //console.log('Starting Request', JSON.stringify(request, null, 2));
      console.log(
        'Sending request:',
        request.baseURL,
        request.url,
        request.method,
      );
      return request;
    });
    this.axios.interceptors.response.use(response => {
      console.log('Got response: ', response);
      return response;
    });
  }
}
