import MicrositeApi from './api/microsite-api';
import APIClient from './api-client';
import config from '../config/config';
import AuthApi from './api/auth-api';
import ListApi from './api/list-api';

function api(): {
  auth: AuthApi
  microsite: MicrositeApi
  lists: ListApi
} {
  const apiClient = new APIClient(config.apiBaseUrl)
  return {
    auth: new AuthApi(apiClient),
    lists: new ListApi(apiClient),
    microsite: new MicrositeApi(apiClient)
  };
}

export default api