import {FunctionComponent} from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFoundPage from '../../../pages/NotFoundPage';
import SubscriberListPage from './SubscriberListPage';

const ListRoutes: FunctionComponent = () => {
  return (
    <Routes>
      <Route path=":id/subscribers" element={<SubscriberListPage/>}/>
      <Route path="*" element={<NotFoundPage/>}/>
    </Routes>
  );
}

export default ListRoutes;
