import {Component, ContextType} from 'react';
import MicrositePageContainer from '../../components/page/MicrositePageContainer';
import MicrositeContext from '../../contexts/microsite-context';
import QRCodeLoading from '../../components/loading/qr-code-loading';
import api from '../../services/api';
import {Navigate, Route, Routes} from 'react-router-dom';
import MicrositeHome from './MicrositeHome';
import MicrositeType from '../../types/microsite-type';
import SubscribePage from './SubscribePage';
import MicrositeService from '../../services/microsite-service';
import AppContext from '../../contexts/app-context';
import AppContextType from '../../types/app-context-type';
import NotFoundPage from '../../pages/NotFoundPage';
import LoginPage from './LoginPage';
import AuthUser from '../../models/auth-user';
import EditMicrositePage from './EditMicrositePage';
import DelayedTasker from '../../utils/DelayedTasker';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import SubscribableResult from '../../models/subscribable-result';
import ManageMicrositePage from './ManageMicrositePage';
import ListRoutes from './lists/ListRoutes';
import VotePage from './VotePage';
import config from '../../config/config';

type MicrositeIndexProps = {
  slug: string
}

type MicrositeIndexState = {
  microsite?: MicrositeType
  error: boolean
  tooSlowLoading: boolean // If it is taking too long to load then display a message to the user
}

class MicrositeApp extends Component<MicrositeIndexProps, MicrositeIndexState> {
  static contextType = AppContext;
  context!: ContextType<typeof AppContext>
  private micrositeService: MicrositeService;
  private micrositeSubscription?: SubscribableResult<MicrositeType>;

  constructor(props: MicrositeIndexProps, context: AppContextType) {
    super(props, context);
    this.state = {
      error: false,
      tooSlowLoading: false
    }
    this.micrositeService = this.createMicrositeService();
  }

  private createMicrositeService() {
    const apiContainer = api();
    return new MicrositeService(apiContainer.microsite, apiContainer.lists);
  }

  componentDidMount() {
    this.reloadMicrosite(this.context.authUser);
    this.context.onAuthUserChanged(this.handleAuthUserChanged.bind(this));
  }

  componentWillUnmount() {
    this.context.removeOnAuthUserChanged(this.handleAuthUserChanged.bind(this));
    if (this.micrositeSubscription) this.micrositeSubscription.unsubscribe(this.handleMicrositeSubscriptionUpdate.bind(this));
  }

  // Bindable handlers for when subscription provides new version of microsite
  private handleMicrositeSubscriptionUpdate(microsite: MicrositeType | undefined) {
    this.setState({microsite});
  }

  private reloadMicrosite(authUser: AuthUser | null) {
    let didLoad = false;
    const maxLoadTime = 5_000; // Max milliseconds to wait
    const tasker = new DelayedTasker();
    tasker.doAfter(maxLoadTime, () => {
      if (!didLoad) {
        this.setState({
          tooSlowLoading: true
        });
      }
    });

    if (this.micrositeSubscription) this.micrositeSubscription.unsubscribe(this.handleMicrositeSubscriptionUpdate.bind(this));
    this.micrositeService.get(this.props.slug, authUser).then(subscription => {
      subscription.subscribe(this.handleMicrositeSubscriptionUpdate.bind(this));
      didLoad = true;
    }).catch(e => {
      didLoad = true;
      this.setState({error: true});
    });
  }


  handleAuthUserChanged(authUser: AuthUser | null) {
    if (authUser !== this.context.authUser) {
      this.reloadMicrosite(authUser);
    }
  }

  render() {
    const qrWidth = 256;

    if (this.state.microsite === undefined) {
      return (
        <MicrositePageContainer>
          {this.state.tooSlowLoading && (
            <div className="txt-center m-b">
              <ActivityIndicator/> <strong>So slow...</strong><br/>
              The page may still load, but it is not looking good.
            </div>
          )}
          {this.state.error ? (
            <div className="txt-center">
              <h1>It's not you. It's us.</h1>
              <p>We are sorry, but an unknown error occurred.</p>
            </div>
          ) : (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <QRCodeLoading size={qrWidth}/>
            </div>
          )}
        </MicrositePageContainer>
      )
    }

    let hasVoted = window.localStorage.getItem('voted') === 'true';
    if (config.enableVoting && hasVoted) {
      const sDateVoted: string = window.localStorage.getItem('votedDate') ?? '';
      const dateVoted = new Date(parseInt(sDateVoted));
      const lastVotedHours = (Date.now() - dateVoted.getTime()) / 1000 / 60 / 60;
      if (lastVotedHours > 24) { // Only allow 1 vote every X hours
        hasVoted = false;
      }
    }

    return (
      <MicrositeContext.Provider value={{
        microsite: this.state.microsite,
        service: this.micrositeService
      }}>
        <Routes>
          <Route path="" index element={<MicrositeHome/>}/>
          <Route path="subscribe" element={<SubscribePage/>}/>
          {(!config.enableVoting || hasVoted) && <Route path="subscribe" element={<SubscribePage/>}/>}
          {config.enableVoting && !hasVoted && <Route path="subscribe" element={<VotePage/>}/>}
          <Route path="manage" element={<ManageMicrositePage/>}/>
          <Route path="edit" element={<EditMicrositePage/>}/>
          <Route path="lists/*" element={<ListRoutes/>}/>
          {/*<Route path="/handoff/:token" element={<HandleHandoff/>} />*/}
          <Route path="/login" element={this.context.authUser === null ? <LoginPage/> : <Navigate to="/"/>}/>
          <Route path="*" element={<NotFoundPage/>}/>
        </Routes>
      </MicrositeContext.Provider>
    );
  }
}

export default MicrositeApp;
