import {FunctionComponent, ReactNode, useEffect, useRef, useState} from 'react';
import './EditableText.css';
import classNames from 'classnames';
import ActivityIndicator from '../common/ActivityIndicator';
import {ensurePromise} from '../../utils/promise';

type EditTextProps = {
  text: string
  // error?: boolean // Whether the "current value" is valid
  onChange: (text: string) => Promise<void> | void // Only updated once the text is committed by pressing ENTER
  validate?: (text: string) => boolean // Updates parent with the current internal value (which is not committed)
  allow?: (text: string) => boolean // Whether the entered text is allowed to be entered
  block?: boolean // Display as full block instead of inline
  placeholder?: ReactNode // The text that is displayed when there is an empty string
};

const EditableText: FunctionComponent<EditTextProps> = ({block, onChange, validate, allow, placeholder, text}) => {
  const [editingText, setEditingText] = useState<string>(text); // Allow text to be edited locally and then commit with onChange
  const [editing, setEditing] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(true);
  const textRef = useRef<HTMLSpanElement>(null);
  const editingRef = useRef<HTMLInputElement>(null);
  const [saving, setSaving] = useState<boolean>(false); // Show activity indicator (only when onChange(...) has a promise result
  if (!placeholder) placeholder = 'Click to edit';
  // If text prop changes then update local state - allows text to be edited locally or cancelled without committing
  // useEffect(() => {
  //   const newValid = validate ? validate(text) : true;
  //   if (newValid !== valid || text !== editingText) {
  //     setEditingText(text);
  //     setValid(newValid);
  //   }
  // }, [text, setEditingText, editingText, valid, setValid, validate]);

  // Handle width changes
  useEffect(() => {
    setValid(validate ? validate(editingText) : true);
  }, [editingText, /* */ setValid, validate]);

  // Focus input when changed to editing
  useEffect(() => {
    if (editing) {
      editingRef.current?.select();
    }
  }, [editing, validate, setValid]);

  // Handle click outside while editing
  useEffect(() => {
    const handleClickOutside = () => {
      if (editing) {
        setEditingText(text);
        setEditing(false);
      }
    }
    // Delay event handler being added so that it is not immediately called when clicking THIS element
    // setTimeout(() => {
      document.body.addEventListener('click', handleClickOutside);
    // }, 50);
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [editing, text]);

  return (
    editing ? (
      <span className={saving === undefined ? undefined : 'EditableText--saving'}>
        <input ref={editingRef}
               className={classNames('EditableText', {block, invalid: !valid, saving})}
               onClick={ev => {
                 ev.stopPropagation();
                 ev.preventDefault();
               }}
               onKeyDown={ev => {
                 if (ev.key === 'Enter' && valid) {

                   ensurePromise(onChange(editingText)).then(() => {
                     setSaving(false);
                     setEditing(false);
                     // setEditingText('');
                   });
                 } else if (ev.key === 'Escape') {
                   setEditingText(text); // Restore text
                   setEditing(false);
                 }
               }}
               value={editingText}
               onChange={ev => {
                 if (saving) return; // No changes during save
                 if (allow && allow(ev.target.value) === false) return;
                 setEditingText(ev.target.value);
               }}
               style={{
                 width: block ? '100%' : ((editingText.length < 10 ? 10 : editingText.length) + 'ch')
               }}
        />
        {saving && <span className="EditableText__activity"><ActivityIndicator/></span>}
      </span>
    ) : (
      <span ref={textRef}
            className={classNames('EditableText', {block})}
            onClick={ev => {
              ev.preventDefault();
              ev.stopPropagation();
              setEditing(true);
              setEditingText(text);
            }}
      >
        {text.length === 0 ? placeholder : text}
      </span>
    )
  );
  // return (
  //   editing ? (
  //       <input ref={ref2}
  //              className="EditableText"
  //              onClick={ev => {
  //                ev.stopPropagation();
  //                ev.preventDefault();
  //              }}
  //              onKeyDown={ev => {
  //                if (ev.key === 'Enter') {
  //                  setEditing(false);
  //                  onChange(editingText);
  //                } else if (ev.key === 'Escape') {
  //                  setEditingText(text); // Restore text
  //                  setEditing(false);
  //                }
  //              }}
  //              value={editingText}
  //              onChange={ev => setEditingText(ev.target.value)}/>
  //     )
  //     :
  //     (
  //       <span ref={ref}
  //             className="EditableText"
  //             onClick={ev => {
  //               // ev.stopPropagation();
  //               ev.preventDefault();
  //               setEditing(true);
  //             }}
  //       >{text.length === 0 ? 'Click to edit' : text}</span>
  //     )
  // );
}

export default EditableText;
