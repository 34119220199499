import {FunctionComponent} from 'react';
import PageContainer, {PageContainerProps} from './PageContainer';
import PageHeader from './PageHeader';

type MicrositePageContainerProps = PageContainerProps & {};

const MicrositePageContainer: FunctionComponent<MicrositePageContainerProps> = (props) => {
  return (
    <PageContainer {...props}
      pageHeader={<PageHeader logoSize={props.logoSize} />}
    />
  );
}

export default MicrositePageContainer;
