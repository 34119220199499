import {FunctionComponent, useEffect, useState} from 'react';
import PageContainer from '../components/page/PageContainer';
import ActivityIndicator from '../components/common/ActivityIndicator';
import {useNavigate} from 'react-router-dom';
import AuthUser from '../models/auth-user';
import api from '../services/api';
import Button, {ButtonType} from '../components/common/Button';

type HandleHandoffProps = {
  code: string
  onAuthUser: (authUser: AuthUser | null) => void
}
const HandleHandoff: FunctionComponent<HandleHandoffProps> = ({code, onAuthUser}) => {
  const [error, setError] = useState<boolean>(false);

  const nav = useNavigate();
  useEffect(() => {
    // console.log('Handle Handoff: ', code); if (code.length > 0) return;
    api().auth.validateHandoff(code).then(authUser => {
      if (authUser === null) {
        setError(true);
      } else {
        onAuthUser(authUser);
      }
    }).catch(() => {
      setError(true);
    });
    nav('/', {replace: true});
  }, [code, nav, onAuthUser]);

  return (
    <PageContainer>
      <div className="txt-center">
        {error ? (
          <>
            This is embarrassing.  We could not automatically log you in.
            <Button type={ButtonType.Primary} onClick={() => onAuthUser(null)}>Continue</Button>
          </>
          ) : (
          <>
            <ActivityIndicator/> loading you into the driver's seat
          </>
        )}
      </div>
    </PageContainer>
  );
}

export default HandleHandoff;
