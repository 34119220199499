import {FunctionComponent} from 'react';
import AccountPageContainer from '../../components/page/AccountPageContainer';
import FormRow from '../../components/form/FormRow';
import ButtonSet from '../../components/common/ButtonSet';
import Button, {ButtonType} from '../../components/common/Button';
import {useNavigate} from 'react-router-dom';

const WelcomePage: FunctionComponent = () => {
  const nav = useNavigate();

  return (
    <AccountPageContainer>
      <div className="txt-center" style={{backgroundColor: '#fff'}}>
        <FormRow>
          <ButtonSet>
            <Button type={ButtonType.Primary} onClick={() => {
              nav('/register');
            }}>Register</Button>
            <Button type={ButtonType.Primary} onClick={() => {
              nav('/login');
            }}>Log In</Button>
          </ButtonSet>
        </FormRow>
        {/*<FormRow>*/}
        {/*  <Button type={ButtonType.Link}*/}
        {/*          onClick={() => {*/}
        {/*          }}>*/}
        {/*    What is Follow the Craft?*/}
        {/*  </Button>*/}
        {/*</FormRow>*/}
      </div>
    </AccountPageContainer>
  );
}

export default WelcomePage;
