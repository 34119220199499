import {FunctionComponent, useContext, useState} from 'react';
import logo_svg from '../../assets/img/logo.svg';
import {useNavigate} from 'react-router-dom';
import Button, {ButtonType} from '../common/Button';
import classNames from 'classnames';
import AppContext from '../../contexts/app-context';
import HamburgerButton from '../common/HamburgerButton';

import './PageHeader.css';


export enum LogoSize {
  _,
  Small,
  Normal,
  Large
}

type PageHeaderProps = {
  logoSize?: LogoSize
}
const PageHeader: FunctionComponent<PageHeaderProps> = ({logoSize}) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const {authUser, setAuthUser} = useContext(AppContext);
  const nav = useNavigate();
  if (!logoSize) logoSize = LogoSize.Normal;
  return (
    <div className="PageHeader">
      <div style={{textAlign: 'center'}}>
        <Button onClick={() => nav('/')}>
          <div className="PageHeader__logo__container">
            <img src={logo_svg} className={classNames('PageHeader__logo', {
              "small": logoSize === LogoSize.Small,
              "normal": logoSize === LogoSize.Normal,
              "large": logoSize === LogoSize.Large
            })} alt="Logo"/>
            <div className="PageHeader__logo__beta">ALPHA</div>
          </div>
        </Button>
        {authUser !== null && (
          <div className="PageHeader__nav">
            <HamburgerButton onClick={ev => {
              ev.stopPropagation();
              setShowMenu(!showMenu);
            }}/>
            <div className={classNames('PageHeader__menu', {visible: showMenu})}>
              <Button type={ButtonType.Link} onClick={() => setAuthUser(null)}>Log Out</Button>
            </div>
          </div>
        )}

        {authUser === null && (
          <div className="PageHeader__nav">
            <HamburgerButton onClick={ev => {
              ev.stopPropagation();
              setShowMenu(!showMenu);
            }}/>
            <div className={classNames('PageHeader__menu', {visible: showMenu})}>
              <Button type={ButtonType.Link} onClick={() => nav('/login')}>Log In</Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PageHeader;
