import {FunctionComponent} from 'react';
import './qr-code-loading.css';

type QRCodeLoadingProps = {
  size: number
};

const QRCodeLoading: FunctionComponent<QRCodeLoadingProps> = ({size}) => {
  return (
    <div className="QRCodeLoading" style={{width: size, height: size, maxWidth: '100%'}}>
      Loading
    </div>
  );
}

export default QRCodeLoading;