import {createContext, FunctionComponent, PropsWithChildren, ReactNode} from 'react';
import StyleVariation from '../../enums/style-variation';
import './Module.css';
import {childrenNotOfType, childrenNotOfTypes, childrenOfType} from '../../utils/react-children';
import classNames from 'classnames';

type ModuleProps = PropsWithChildren<{
  variation?: StyleVariation
}>

type ModuleType = FunctionComponent<ModuleProps>;

const ModuleContext = createContext({
  variation: StyleVariation.Default
})

const Module: ModuleType = ({children, variation}) => {
  if (!variation) variation = StyleVariation.Default;
  const bodyChild = childrenOfType(children, ModuleBody.name);
  const body = bodyChild.length > 0 ?
    childrenNotOfType(bodyChild, ModuleFooter.name) // Content already wrapped by ModuleBody
    :
    (
      <ModuleBody>
        {childrenNotOfTypes(children, [
          ModuleHeader.name,
          ModuleFooter.name,
          ModuleNotice.name
        ], true)}
      </ModuleBody>
    );
  return (
    <div className={classNames('Module', 'Module--' + variation)}>
      <ModuleContext.Provider value={{variation: variation ?? StyleVariation.Default}}>
        {childrenOfType(children, ModuleHeader.name)}
        {childrenOfType(children, ModuleNotice.name)}
        {body}
        {childrenOfType(children, ModuleFooter.name)}
      </ModuleContext.Provider>
    </div>
  );
}

////////////////////////////////////////////////////////////

type ModuleHeaderProps = {
  title: ReactNode
  right?: ReactNode
};

const ModuleHeader: FunctionComponent<ModuleHeaderProps> =({
                   right,
                   title
                 }) => {
  return (
    <div className="ModuleHeader flex">
      <div className="ModuleHeader__title flex-grow">
        {title}
      </div>
      {right && (
        <div>
          {right}
        </div>
      )}
    </div>
  );
};

////////////////////////////////////////////////////////////

export type ModuleBodyProps = PropsWithChildren<{
  padded?: boolean
}>;

const ModuleBody: FunctionComponent<ModuleBodyProps> = ({children, padded}) => {
  return (
    <div className={classNames('ModuleBody', {padded: padded !== false})}>
      {children}
    </div>
  );
};

////////////////////////////////////////////////////////////

type ModuleFooterProps = PropsWithChildren;

const ModuleFooter: FunctionComponent<ModuleFooterProps> = ({children}) => {
  return (
    <div className="ModuleFooter">
      {children}
    </div>
  );
};

////////////////////////////////////////////////////////////

type ModuleNoticeProps = PropsWithChildren;

const ModuleNotice: FunctionComponent<ModuleNoticeProps> = ({children}) => {
  return (
    <div className="txt-center ModuleNotice">
      {children}
    </div>
  );
};

////////////////////////////////////////////////////////////

export default Module;
export {ModuleHeader, ModuleBody, ModuleFooter, ModuleNotice}
