import {FunctionComponent, KeyboardEvent} from 'react';
import './ValidatorInput.css';
import TextInput from './TextInput';

type LoginValidationInputProps = {
  code: string
  onChanged: (code: string) => void
  disabled?: boolean
  onKeyUp?: (ev: KeyboardEvent<HTMLInputElement>) => void
}
const LoginValidationInput: FunctionComponent<LoginValidationInputProps> = ({code, disabled, onChanged, onKeyUp}) => {
  return (
    <div className="ValidatorInput">
      <TextInput value={code}
                 disabled={disabled === true}
                 onChanged={newCode => {
                   newCode = newCode.replace(/[^0-9]+/, '');
                   if (newCode.match(/[^0-9]/)) return;
                   if (newCode !== code) onChanged(newCode);
                 }}
                 onKeyUp={onKeyUp}
      />
    </div>
  );
}

export default LoginValidationInput;