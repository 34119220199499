import {Children, isValidElement, ReactNode} from 'react';

const filterReactChildren = (children: ReactNode, callback: (type: string) => boolean, followTree: boolean = false): Array<ReactNode> => {
  return Children.toArray(children).filter(c => {
    if (!isValidElement(c)) return false;
    if (typeof(c.type) === 'string') callback('string');

    if (typeof(c.type) !== 'string' && c.type.name === '') {
      console.warn('Name is empty.  Probably lost with compile.');
      return false;
    }

    return typeof(c.type) === 'string' ? callback('string') : callback(c.type.name);
  });
}

/**
 * Get React children that are a specific component type
 * @param children
 * @param type
 * @param followTree
 */
const childrenOfType = (children: ReactNode, eltype: string, followTree: boolean = false): Array<ReactNode> => {
  const result = childrenOfTypes(children, [eltype], followTree);

  return result;
}

/**
 * Get React children that are specific component types
 * @param children
 * @param types
 * @param followTree
 */
const childrenOfTypes = (children: ReactNode, types: Array<string>, followTree: boolean = false): Array<ReactNode> => {
  return filterReactChildren(children, childType => {
    return types.indexOf(childType) >= 0;
  }, followTree);
}

/**
 * Get React children that are NOT of a specific component type
 * @param children
 * @param type
 * @param followTree
 */
const childrenNotOfType = (children: ReactNode, type: string, followTree: boolean = false): Array<ReactNode> => {
  return childrenNotOfTypes(children, [type], followTree);
}
/**
 * Get React children that are not of specific component types
 * @param children
 * @param types
 * @param followTree
 */
const childrenNotOfTypes = (children: ReactNode, types: Array<string>, followTree: boolean = false): Array<ReactNode> => {
  return filterReactChildren(children, childType => types.indexOf(childType) < 0, followTree);
}

export {
  childrenOfType,
  childrenOfTypes,
  childrenNotOfType,
  childrenNotOfTypes
};
