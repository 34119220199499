import {FunctionComponent} from 'react';
import classnames from 'classnames';
import './Checkbox.css';

type CheckboxProps = {
  checked: boolean
  onToggle?: () => void
  disabled?: boolean
};

const Checkbox: FunctionComponent<CheckboxProps> = ({
                                                      checked,
                                                      disabled,
                                                      onToggle
                                                    }) => {
  return (
    <div className={classnames('Checkbox', {checked, disabled})} onClick={() => {
      if (onToggle && disabled !== true) onToggle();
    }}>
      <div className="Checkbox__mark"/>
      {checked && (
        <svg viewBox="0 0 30 30">
          <path stroke="#fff" strokeWidth={3} d="M6,16 L13,22 L23,9" fill="transparent"/>
        </svg>
      )}
    </div>
  );
}

export default Checkbox;
