import React, {FunctionComponent, useContext, useEffect} from 'react';
import MicrositePageContainer from '../../components/page/MicrositePageContainer';
import {LogoSize} from '../../components/page/PageHeader';
import MicrositeContext from '../../contexts/microsite-context';
import {useNavigate} from 'react-router-dom';
import AppContext from '../../contexts/app-context';
import MicrositeView, {RemoveListType} from '../../components/microsite/MicrositeView';
import ViewMode from '../../enums/view-mode';
import Button, {ButtonType} from '../../components/common/Button';

const EditMicrositePage: FunctionComponent = () => {
  const {authUser} = useContext(AppContext);
  const {microsite, service} = useContext(MicrositeContext);
  const nav = useNavigate();

  useEffect(() => {
    if (authUser === null) nav('/');
  }, [authUser, nav]);

  const viewMode = authUser !== null && service.isMicrositeManager(authUser, microsite.id) ? ViewMode.Edit : ViewMode.View;

  const managedListIxes: Array<number> = [];
  if (authUser !== null) {
    microsite.lists.forEach((list, ix) => {
      if (service.isListManager(authUser, list.id)) managedListIxes.push(ix);
    });
  }

  return (
    <MicrositePageContainer logoSize={LogoSize.Small} padded={false}>
      <div className="txt-center m-t">
        <Button type={ButtonType.Secondary} onClick={() => nav('/manage')}>
          &laquo; Back to Manage
        </Button>
      </div>
      <MicrositeView viewMode={viewMode}
                     microsite={microsite}
                     listIxes={microsite.lists.map((_, ix) => ix)}
                     managedListIxes={managedListIxes}
                     updateListAtIndex={(ix, list) => {
                       return service.saveList(authUser!, microsite.slug, list).then(() => {});
                     }}
                     removeListAtIndex={(removeType, listIx) => {
                       if (removeType === RemoveListType.Unlink) return service.disassociateList(authUser!, microsite.slug, microsite.lists[listIx].id).then(() => {});
                       else if (removeType === RemoveListType.Archive) return service.archiveList(authUser!, microsite.slug, microsite.lists[listIx].id).then(() => {});
                       else {
                           alert('Unsupported removal type: ' + removeType);
                           return Promise.resolve();
                       }
                     }}
                     updateMicrosite={microsite => {
                       return service.save(authUser!, microsite).then(() => {});
                     }}
                     addList={list => {
                       return service.addList(authUser!, microsite.slug, list).then(() => {})
                     }}
      />
    </MicrositePageContainer>
  );
}

export default EditMicrositePage;
