import React, {FunctionComponent, useContext, useEffect} from 'react';
import MicrositePageContainer from '../../components/page/MicrositePageContainer';
import {LogoSize} from '../../components/page/PageHeader';
import MicrositeContext from '../../contexts/microsite-context';
import {useNavigate} from 'react-router-dom';
import AppContext from '../../contexts/app-context';
import MicrositeView from '../../components/microsite/MicrositeView';
import Button, {ButtonType} from '../../components/common/Button';

const ManageMicrositePage: FunctionComponent = () => {
  const {authUser} = useContext(AppContext);
  const {microsite, service} = useContext(MicrositeContext);
  const nav = useNavigate();

  useEffect(() => {
    if (authUser === null) nav('/');
  }, [authUser, nav]);

  // const viewMode = authUser !== null && service.isMicrositeManager(authUser, microsite.id) ? ViewMode.Edit : ViewMode.View;

  const managedListIxes: Array<number> = [];
  if (authUser !== null) {
    microsite.lists.forEach((list, ix) => {
      if (service.isListManager(authUser, list.id)) managedListIxes.push(ix);
    });
  }

  return (
    <MicrositePageContainer logoSize={LogoSize.Small} padded={false}>
      <div className="txt-center m-t">
        <Button type={ButtonType.Secondary} onClick={() => nav('/edit')}>
          Edit Labels & Links
        </Button>
      </div>
      <MicrositeView microsite={microsite}
                     listIxes={microsite.lists.map((_, ix) => ix)}
                     managedListIxes={managedListIxes}
      />
    </MicrositePageContainer>
  );
}

export default ManageMicrositePage;
