import React, {FunctionComponent, useContext, useState} from 'react';
import MicrositeType from '../../types/microsite-type';
import ErrorList from '../common/ErrorList';
import ActionState, {ActionStatus} from '../../types/action-state';
import Button, {ButtonType} from '../common/Button';
import Padded from '../common/Padded';
import Module, {ModuleBody, ModuleHeader, ModuleNotice} from '../common/Module';
import AppContext from '../../contexts/app-context';
import ListType from '../../types/list-type';
import AddList from './AddList';
import ViewMode from '../../enums/view-mode';
import AddListType from '../../types/add-list-type';
import ButtonSet from '../common/ButtonSet';
import update from 'immutability-helper';
import EditableText from '../form/EditableText';
import LinkList from '../lists/LinkList';
import {useNavigate} from 'react-router-dom';
import {listTypeLabels} from '../../constants/list-type-labels';

type MicrositeProps = {
  viewMode?: ViewMode
  saving?: boolean
  microsite: MicrositeType
  listIxes: Array<number> // List indexes of microsite.lists[x] to show
  managedListIxes?: Array<number> // The lists that the logged in user can edit
  updateMicrosite?: (microsite: MicrositeType) => Promise<void> | undefined
  updateListAtIndex?: (index: number, list: ListType) => Promise<void>
  removeListAtIndex?: (removeType: RemoveListType, index: number) => Promise<void>
  addList?: (list: AddListType) => Promise<void>
};

export enum RemoveListType {
    Archive,
    Unlink
}

type RemoveListState = {
    type: RemoveListType
    index: number
}

const MicrositeView: FunctionComponent<MicrositeProps> = ({
                                                            viewMode,
                                                            microsite,
                                                            listIxes,
                                                            managedListIxes,
                                                            saving,
                                                            updateMicrosite,
                                                            updateListAtIndex,
                                                            addList,
                                                            removeListAtIndex,
                                                          }) => {
  if (viewMode === undefined) viewMode = ViewMode.View;
  if (managedListIxes === undefined) managedListIxes = [];
  const [confirmRemoveList, setConfirmRemoveList] = useState<RemoveListState | undefined>();

  // const [name, setName] = useState<string>(microsite.name);
  const [status] = useState<ActionState>({
    current: ActionStatus.Normal,
    errors: []
  });
  const [isAddingList, setIsAddingList] = useState<boolean>(false);
  // const [isSavingList, setIsSavingList] = useState<boolean>(false);
  const {authUser} = useContext(AppContext);
  const nav = useNavigate();

  return (
    <>
      <Padded>
        <ErrorList errors={status.errors}/>

          <h1 className="txt-center" style={{fontWeight: 'normal'}}>
            {viewMode === ViewMode.Edit && updateMicrosite !== undefined ? (
              <EditableText block={true}
                            text={microsite.name}
                            validate={value => value.length > 0 && value.length <= 30}
                            onChange={name => updateMicrosite(update(microsite, {name: {$set: name}}))}
              />
            ) : (
              microsite.name
            )}
          </h1>

      </Padded>
      <div style={{backgroundColor: '#f0f0f0'}}>
        <Padded>

          {/**
            *  Header
            */}
          <header>
            <div className="flex m-b">
              <div className="flex-grow align-center">
                <h2>{listTypeLabels.movie.plural}</h2>
              </div>
              {viewMode === ViewMode.Edit && addList !== undefined && !isAddingList && (
                <div className="align-center">
                  <Button type={ButtonType.Secondary} onClick={() => {
                    setIsAddingList(!isAddingList)
                  }}>
                    + Add
                  </Button>
                </div>
              )}
            </div>
          </header>

          {/**
           *  Add List
           */}
          {isAddingList && addList !== undefined && (
            <div className="m-b">
              <AddList onAdd={list => {
                addList(list);
                setIsAddingList(false);
              }} onCancel={() => {
                setIsAddingList(false);
              }}/>
            </div>
          )}

          {/**
           *  Lists
           */}
          {!isAddingList && (
            <main>
              {listIxes.map(ix => microsite.lists[ix]).map((list, ix) => {
                const isManager = authUser !== null && managedListIxes !== undefined && managedListIxes.indexOf(ix) >= 0;

                return (
                  <Module key={ix + '-' + list.id}>
                    <ModuleHeader title={
                      viewMode === ViewMode.Edit && isManager && updateListAtIndex ?
                        <EditableText text={list.name}
                                      validate={value => value.length > 0 && value.length <= 50}
                                      onChange={name => {
                                        updateListAtIndex(ix, update(list, {
                                          name: {$set: name}
                                        }))
                                      }}/>
                        :
                        list.name
                    }
                                   right={
                                     viewMode === ViewMode.Edit && removeListAtIndex !== undefined ? (
                                       confirmRemoveList === undefined || confirmRemoveList.index !== ix ?
                                         <ButtonSet>
                                             <Button type={ButtonType.Link} onClick={() => {
                                                 setConfirmRemoveList({type: RemoveListType.Archive, index: ix});
                                             }}>Archive</Button>
                                             <Button type={ButtonType.Link} onClick={() => {
                                                 setConfirmRemoveList({type: RemoveListType.Unlink, index: ix});
                                             }}>Unlink</Button>
                                         </ButtonSet>
                                         :
                                         <ButtonSet>
                                             {confirmRemoveList.type === RemoveListType.Unlink && <>Confirm unlink?</>}
                                             {confirmRemoveList.type === RemoveListType.Archive && <>Confirm archive?</>}
                                           <Button type={ButtonType.Link} onClick={() => {
                                             return removeListAtIndex(confirmRemoveList.type, ix).then(() => {
                                               setConfirmRemoveList(undefined);
                                             });
                                           }}>Yes</Button>
                                           <Button type={ButtonType.Link} onClick={() => {
                                             setConfirmRemoveList(undefined);
                                           }}>No</Button>
                                         </ButtonSet>
                                     ) : (
                                       isManager ? (
                                         <Button type={ButtonType.Link} onClick={() => {
                                           nav('/lists/' + list.id + '/subscribers')
                                         }}>
                                           {(new Intl.NumberFormat().format(list.subscribers))} subs
                                         </Button>
                                       ) : (
                                         (new Intl.NumberFormat().format(list.subscribers)) + ' subs'
                                       )
                                     )
                                   }
                    />
                    <ModuleBody>
                      {isManager && list.links.length ? <>Links visible to public:</> : null}
                      <LinkList links={list.links}
                                onChange={!isManager || updateListAtIndex === undefined ? undefined : links => {
                                  return updateListAtIndex(ix, update(list, {
                                    links: {$set: links}
                                  }));
                                }}
                      />
                    </ModuleBody>

                    {viewMode === ViewMode.View || isManager ? null : (
                      <ModuleNotice>You do not manage this contact list, but it is linked to your site.</ModuleNotice>
                    )}
                  </Module>
                )
              })}
            </main>
          )}
        </Padded>
      </div>
    </>
  );
}

export default MicrositeView;
