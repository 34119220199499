import React, {FunctionComponent, useState} from 'react';
import LinkTypeIcon, {IconSize} from '../common/LinkTypeIcon';
import Button, {ButtonType} from '../common/Button';
import ListLinkType from '../../types/list-link-type';
import update from 'immutability-helper';
import LinkTypeService, {LinkTypeServiceFactory} from '../../services/link-type-service';
import EditableText from '../form/EditableText';
import {ensurePromise} from '../../utils/promise';
import './LinkList.css';

type LinkListProps = {
  links: Array<ListLinkType>
  onChange?: (links: Array<ListLinkType>) => void | Promise<void>
};

const LinkList: FunctionComponent<LinkListProps> = ({links, onChange}) => {
  const linkTypeService = LinkTypeServiceFactory.create();
  const [addLink, setNewLink] = useState<ListLinkType>({
    type: '',
    address: ''
  });

  return (
    <>
      {onChange && links.length === 0 && (
        <div className="txt-center">
          Add some links for your fans!
        </div>
      )}
      <div className="LinkList__links">
        {links.map((link, linkIx) => {
          return (
            <div key={linkIx + '-' + link.address} className="LinkList__link flex flex--gap">
              <div>
                <LinkTypeIcon type={link.type} subtype={link.subtype} size={IconSize.Small}/>
              </div>

              {onChange ? (
                <div className="LinkList__link__edit">
                  <Button type={ButtonType.Link}>
                    <EditableLink link={link}
                                  service={linkTypeService}
                                  onChange={link => {
                                    return onChange(update(links, {
                                      [linkIx]: {$set: link}
                                    }));
                                  }}/>
                  </Button>

                  <div className="LinkList__link__actions">
                    <Button type={ButtonType.DangerLink}
                            onClick={() => {
                              if (onChange) {
                                onChange(update(links, {
                                  $splice: [[linkIx, 1]]
                                }));
                              }
                            }}
                    >
                      [Remove]
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <a href={linkTypeService.linkHref(link)} className="Button Button--link">{link.address}</a>
                </div>
              )}
            </div>
          )
        })}
      </div>
      {onChange && (
        <div style={{margin: '5px 0', borderTop: '1px solid #e1e1e1', paddingTop: 5}}>
          <Button type={ButtonType.Link}>
            <EditableLink block={true}
                          link={addLink}
                          service={linkTypeService}
                          onChange={link => {
                            setNewLink(link); // Temporarily update so that we can change state and force a refresh to blank values
                            return ensurePromise(onChange(update(links, {
                              $push: [link]
                            }))).then(() => {
                              setNewLink({type: '', address: ''});
                            })
                          }}/>
          </Button>
        </div>
      )}
    </>
  );
}

type EditableLinkProps = {
  block?: boolean
  link: ListLinkType
  onChange: (link: ListLinkType) => Promise<void> | void
  service: LinkTypeService
}
const EditableLink: FunctionComponent<EditableLinkProps> = ({link, onChange, service, block}) => {
  return <EditableText text={link.address}
                       block={block}
                       validate={address => service.guessAddressType(address) !== undefined}
                       placeholder={<div className="txt-center">+ Click to add link, phone, or email</div>}
                       onChange={address => {
                         const linkType = service.guessAddressType(address);
                         return onChange({
                           address,
                           type: linkType!.type,
                           subtype: linkType!.subtype // validate() ensures that linkType return type will be defined
                         })
                       }}
  />
}

export default LinkList;
