import {FunctionComponent, useContext} from 'react';
import MicrositePageContainer from '../../components/page/MicrositePageContainer';
import QuickAuthUser from '../../components/auth/QuickAuthUser';
import AppContext from '../../contexts/app-context';

const LoginPage: FunctionComponent = () => {
  const {setAuthUser} = useContext(AppContext);
  return (
    <MicrositePageContainer>
      <QuickAuthUser onAuth={auth => setAuthUser(auth)}/>
    </MicrositePageContainer>
  );
}

export default LoginPage;
