import {createContext} from 'react';
import MicrositeType from '../types/microsite-type';
import MicrositeService from '../services/microsite-service';

export type MicrositeContextType = {
  microsite: MicrositeType
  service: MicrositeService
};

const MicrositeContext = createContext<MicrositeContextType>({
  microsite: {} as MicrositeType,
  service: {} as MicrositeService
  // managed: false,
  // managedListsIds: []
});

export default MicrositeContext;
