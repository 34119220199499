import {LinkTypeValidator} from '../services/link-type-service';

type LinkTypesConfig = {
  default: string
  types: Array<LinkTypeValidator>
}

const linkTypes: LinkTypesConfig = {
  default: 'website',
  types: [
    {
      type: 'website',
      label: 'Website',
      examples: ['https://www.yourwebsite.com'],
      icon: process.env.PUBLIC_URL + '/assets/img/icons8-link-64.png',
      validFormats: [
        /https?:\/\/.+\..+/
      ],
      validationOrder: 1
    },
    {
      type: 'website',
      subtype: 'facebook',
      label: 'Facebook',
      examples: ['https?://www.facebook.com/profile-page'],
      icon: process.env.PUBLIC_URL + '/assets/img/icons8-facebook.svg',
      validFormats: [
        /https?:\/\/(www\.)?facebook\.com\/.+/
      ]
    },
    {
      type: 'website',
      subtype: 'instagram',
      label: 'Instagram',
      examples: ['https://www.instagram.com/profile-name'],
      icon: process.env.PUBLIC_URL + '/assets/img/icons8-instagram.svg',
      validFormats: [
        /https?:\/\/(www\.)?instagram\.com\/.+/
      ]
    },
    {
      type: 'website',
      subtype: 'linkedin',
      label: 'LinkedIn',
      examples: ['https://www.linkedin.com/profilename'],
      icon: process.env.PUBLIC_URL + '/assets/img/icons8-linkedin.svg',
      validFormats: [
        /https?:\/\/(www\.)?linkedin\.com\/.+/
      ]
    },
    // {
    //   type: 'website',
    //   subtype: 'pinterest',
    //   examples: [],
    //   icon: process.env.PUBLIC_URL + '/assets/img/icons8-pinterest.svg',
    //   validatorFormats: []
    // },
    {
      type: 'website',
      subtype: 'tiktok',
      label: 'TikTok',
      examples: [],
      icon: process.env.PUBLIC_URL + '/assets/img/icons8-tiktok.svg',
      validFormats: [
        /https?:\/\/(www\.)?tiktok\.com\/.+/
      ]
    },
    {
      type: 'website',
      subtype: 'twitter',
      label: 'Twitter',
      examples: [],
      icon: process.env.PUBLIC_URL + '/assets/img/icons8-twitter.svg',
      validFormats: [
        /https?:\/\/(www\.)?twitter\.com\/.+/
      ]
    },
    // {
    //   type: 'website',
    //   subtype: 'whatsapp',
    //   examples: [],
    //   icon: process.env.PUBLIC_URL + '/assets/img/icons8-whatsapp.svg',
    //   validatorFormats: []
    // },
    {
      type: 'website',
      subtype: 'youtube',
      label: 'YouTube',
      examples: ['https://www.youtube.com/channel-name'],
      icon: process.env.PUBLIC_URL + '/assets/img/icons8-youtube.svg',
      validFormats: [
        /https?:\/\/(www)?youtube\.com\/.+/
      ]
    },
    {
      type: 'email',
      label: 'Email',
      examples: ['name@domain.com'],
      icon: process.env.PUBLIC_URL + '/assets/img/icons8-email-30.png',
      validFormats: [
        /.+@.+\..+/
      ],
      linkHref: link => 'mailto:' + link.address
    },
    {
      type: 'phone',
      label: 'Phone',
      examples: ['(999) 999-9999'],
      icon: process.env.PUBLIC_URL + '/assets/img/icon-phone.png',
      validationFormatter: (value) => value.replace(/[^[0-9]+]/, ''),
      validFormats: [
        /^[0-9]{10}$/
      ],
      linkHref: link => 'tel:' + link.address
    }
  ]
};

export default linkTypes;
