import BaseApi from './base-api';
import AuthUser from '../../models/auth-user';
import AddListType from '../../types/add-list-type';
import ListType from '../../types/list-type';
import ListLinkType from '../../types/list-link-type';
import SubscriberType from '../../types/subscriber-type';

// type CreateListRequest = {
//   name: string
//   links: Array<ListLinkType>
//   slug?: string
// }
type ListRequest = {
  name: string
  links: Array<ListLinkType>
  slug?: string // Only for CREATE requests and only if it is being created as part of a MICROSITE list creationg
}

type ListResponse = {
  success: true
  list: ListType
  errors: Array<string>
}

type SubscribersResponse = {
  offset: number
  limit: number
  total: number
  subscribers: Array<SubscriberType>
}

class ListApi extends BaseApi {

  public get(listId: number, authUser: AuthUser): Promise<ListType> {
    return this.apiClient.get<ListResponse>('/lists/' + listId, this.authConfig(authUser))
      .then(response => response.data.list);
  }

  public getSubscribers(authUser: AuthUser, listId: number, limit?: number, offset?: number): Promise<SubscribersResponse> {
    const config = this.authConfig(authUser);
    config.params = {
      offset,
      limit
    };

    return this.apiClient.get<SubscribersResponse>('/lists/' + listId + '/subscribers', config)
      .then(response => response.data);
}

  public create(authUser: AuthUser, list: AddListType, slug?: string): Promise<ListResponse> {
    return this.apiClient.post<ListResponse, ListRequest>(
      '/lists',
      {
        name: list.name,
        links: list.links,
        slug: slug
      },
      this.authConfig(authUser)
    )
      .then(response => response.data);
  }

  public save(authUser: AuthUser, list: ListType): Promise<ListResponse> {
    return this.apiClient.put<ListResponse, ListRequest>(
      '/lists/' + list.id,
      {
        name: list.name,
        links: list.links
      },
      this.authConfig(authUser)
    ).then(response => response.data);
  }

  public subscribe(authUser: AuthUser, listId: number): Promise<boolean> {
    return this.apiClient
      .put<{ success: boolean }, any>(
        '/lists/' + listId + '/subscribe',
        null,
        this.authConfig(authUser)
      ).then(response => response.data.success);
  }

  public unsubscribe(authUser: AuthUser, listId: number): Promise<boolean> {
    return this.apiClient
      .delete<{ success: boolean }, any>(
        '/lists/' + listId + '/subscribe',
        this.authConfig(authUser)
      )
      .then(response => response.data.success);
  }
}

export default ListApi;
