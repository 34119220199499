import {FunctionComponent} from 'react';
import PageContainer from '../components/page/PageContainer';
import PageHeader from '../components/page/PageHeader';

type NotFoundPageProps = {};

const NotFoundPage: FunctionComponent<NotFoundPageProps> = () => {
  return (
    <PageContainer pageHeader={<PageHeader/>}>
      <h1>Page Not Found</h1>
      <p>What an absolute bummer.  You were probably looking for something awesome and found this stupid &quot;Not Found Page.&quot; Ugh.</p>
    </PageContainer>
  );
}

export default NotFoundPage;
