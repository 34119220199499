import {FunctionComponent, useContext, useEffect, useState} from 'react';
import MicrositePageContainer from '../../../components/page/MicrositePageContainer';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import api from '../../../services/api';
import AppContext from '../../../contexts/app-context';
import ListType from '../../../types/list-type';
import SubscriberType from '../../../types/subscriber-type';
import ActivityIndicator from '../../../components/common/ActivityIndicator';
import Button, {ButtonType} from '../../../components/common/Button';
import ButtonSet from '../../../components/common/ButtonSet';

type ListRouteParams = {
  id: string
}

type LimitOffset = {
  offset: number
  limit: number
}

const SubscriberListPage: FunctionComponent = () => {
  const params = useParams<ListRouteParams>();
  const [list, setList] = useState<ListType | undefined>();
  const [subscribers, setSubscribers] = useState<Array<SubscriberType> | undefined>();
  const [limitOffset, setLimitOffset] = useState<LimitOffset | undefined>();
  const authUser = useContext(AppContext).authUser;
  const nav = useNavigate();
  const listId = params.id ? parseInt(params.id) : undefined;

  // const loadSubscribers = () => {
  //   if (authUser === null || listId === undefined) return;
  //   setSubscribers(undefined);
  //
  //   return api().lists.getSubscribers(authUser, listId, limitOffset.limit, limitOffset.offset).then(response => {
  //     setSubscribers(response.subscribers);
  //   });
  // };

  useEffect(() => {
    if (!params.id || authUser === null) return;
    const listId = parseInt(params.id);
    api().lists.get(listId, authUser).then(list => {
      setList(list);
      setLimitOffset({
        offset: 0,
        limit: 20
      })
    });
  }, [params.id, authUser]);

  useEffect(() => {
    if (limitOffset === undefined || authUser === null || listId === undefined) return;

    api().lists.getSubscribers(authUser, listId, limitOffset.limit, limitOffset.offset).then(response => {
      setSubscribers(response.subscribers);
    });
  }, [limitOffset, authUser, listId]);

  if (authUser === null) {
    return <Navigate to="/"/>;
  }

  const hasPrev = limitOffset !== undefined && list !== undefined && limitOffset.offset > 0;
  const hasNext = limitOffset !== undefined && list !== undefined && limitOffset.offset + limitOffset.limit  < list.subscribers;
  let prevOffset = limitOffset === undefined ? 0 : limitOffset.offset - limitOffset.limit;
  let nextOffset = limitOffset === undefined ? 0 : limitOffset.offset + limitOffset.limit;

  return (
    <MicrositePageContainer>
      <div className="txt-center">
        <Button type={ButtonType.Secondary} onClick={() => nav('/manage')}>Back to Lists</Button>
      </div>
      {(list === undefined || subscribers === undefined) && (
        <div className="m-t">
          <ActivityIndicator/> loading
        </div>
      )}

      {list !== undefined && subscribers !== undefined && (
        <div>
          <table className="table">
            {/*<thead>*/}
            {/*<tr>*/}
            {/*  <th>&nbsp;</th>*/}
            {/*  <th style={{textAlign: 'left'}}>Email</th>*/}
            {/*</tr>*/}
            {/*</thead>*/}
            <tbody>
            {subscribers.map((subscriber, ix) => (
              <tr key={ix + '-' + subscriber.email}>
                {/*<td>{Math.ceil((limitOffset!.offset+1)/limitOffset!.limit)*limitOffset!.limit+ix}</td>*/}
                <td>{subscriber.email}</td>
              </tr>
            ))}
            </tbody>
          </table>

          <Pager className="m-t"
                 limit={limitOffset?.limit}
                 offset={limitOffset?.offset}
                 total={list.subscribers}
                 onChangeOffset={(offset, limit) => setLimitOffset({offset, limit})}
          />
          <ButtonSet>
            {hasPrev && (<Button type={ButtonType.Link} onClick={() => setLimitOffset({offset: prevOffset, limit: limitOffset!.limit})}>Previous</Button>)}
            {hasNext && (<Button type={ButtonType.Link} onClick={() => setLimitOffset({offset: nextOffset, limit: limitOffset!.limit})}>Next</Button>)}
          </ButtonSet>
        </div>
      )}
    </MicrositePageContainer>
  );
}

const Pager: FunctionComponent<{
  limit?: number,
  offset?: number,
  total?: number,
  className?: string,
  onChangeOffset: (offset: number, limit: number) => void
}> = ({
        limit,
        offset,
        total,
        className,
        onChangeOffset
      }) => {
  if (limit === undefined || offset === undefined || total === undefined) return null;
  const page = Math.ceil((offset + 1) / limit);
  const pages = Math.ceil(total / limit);
  // const numbers: Array<ReactNode> = [];
  // for (let p = 0; p < pages; p++) {
  //   numbers.push(
  //     <Button type={ButtonType.Link}
  //             onClick={() => {
  //               onChangeOffset(p * limit, limit);
  //             }} key={p}
  //     >
  //       {p + 1}
  //     </Button>
  //   );
  // }
  return <div className={className}>
    <div>Page {page} of {pages}</div>
    {/*<div>*/}
    {/*  <ButtonSet>*/}
    {/*    {numbers}*/}
    {/*  </ButtonSet>*/}
    {/*</div>*/}
  </div>
}

export default SubscriberListPage;
