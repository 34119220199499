type OnSubscriptionUpdatedHandler<T> = (result: T | undefined) => void

class SubscribableResult<T> {
  private onUpdateHandlers: Array<OnSubscriptionUpdatedHandler<T>> = [];
  private _result: T | undefined;

  public constructor(result: T) {
    this._result = result;
  }

  get result(): T | undefined {
    return this._result;
  }

  /**
   * Set result and trigger update to subscribers
   * @param value
   */
  set result(value: T | undefined) {
    this._result = value;
    this.triggerUpdate();
  }

  public subscribe(handler: OnSubscriptionUpdatedHandler<T | undefined>) {
    this.onUpdateHandlers.push(handler);
    handler(this.result); // Let this subscriber know about the current item
  }

  public unsubscribe(handler: OnSubscriptionUpdatedHandler<T>) {
    this.onUpdateHandlers = this.onUpdateHandlers.filter(testHandler => testHandler !== handler);
  }

  /**
   * Send all subscribers an update
   * @private
   */
  private triggerUpdate() {
    this.onUpdateHandlers.forEach(handler => handler(this.result));
  }
}

export default SubscribableResult;
