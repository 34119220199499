import {FunctionComponent} from 'react';
import './LoadingScreen.css';
import logo from '../assets/img/logo.svg';
import classNames from 'classnames';
import ActivityIndicator from '../components/common/ActivityIndicator';

type LoadingPageProps = {
  animate?: boolean
}

const LoadingScreen: FunctionComponent<LoadingPageProps> = ({animate}) => {

  return (
    <div className={classNames('LoadingScreen', {'LoadingScreen--animated': animate})}>
      <div>
        <img src={logo} width={300} alt="Follow the Craft"/>
        <div className="txt-center"><ActivityIndicator/> Loading...</div>
      </div>
    </div>
  );
}

export default LoadingScreen;
