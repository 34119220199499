/**
 * Provide one central setInterval implementation that any number of listeners can tap into
 *
 * Setting a "minInterval" value as part of onTick(handler, minInterval) will
 * require that at least that much time to pass before the handler is called.
 *
 * minInterval is not a guaranteed run rate.  There are multiple factors that come into play.  At the least:
 *
 * - The browser may not trigger the timer at the precise interval specified
 *   by the constructor "interval" value.
 *
 * - If the constructor interval value is set higher than onTick, the onTick handler
 *   will not be called anymore frequently than the constructor value.
 *   For example, if constructor "interval" is set to five seconds (5_000) and onTick is
 *   called with a one-second interval, the onTick(handler, 1000) handler will only be
 *   called one time in five seconds.
 */
type OnTickHandler = (lastRun?: Date) => void;
type OnDebugMessageHandler = (msg: string) => void;
type TickHandlerTuple = [handler: OnTickHandler, minInterval?: number, lastRun?: Date];

class Ticker {
  private onTickHandlerTuples: Array<TickHandlerTuple> = [];
  private onDebugMessageHandlers: Array<OnDebugMessageHandler> = [];
  private timer?: number;
  private readonly _initTime: Date;
  private isTicking: boolean = false;

  constructor(private interval: number = 1000) {
    this._initTime = new Date();
  }

  get initTime(): Date {
    return this._initTime;
  }

  /**
   * Start the global timer
   */
  public start(): void {
    this.stop();
    this.debugMessage('Ticker.start');
    this.timer = window.setInterval(() => {
      this.tick();
    }, this.interval);
  }

  /**
   * Stop any running timer
   */
  public stop(): void {
    this.debugMessage('Ticker.stop (' + (this.timer ? 'timer' : 'no timer') + ')');
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
  }

  private tick(): void {
    if (this.isTicking) return; // Prevent tick from being run multiple times simultaneously
    this.debugMessage('Ticker.tick.  # Handlers: ' + this.onTickHandlerTuples.length);
    this.onTickHandlerTuples.forEach((handlerInterval, ix) => {
      const [handler, interval, lastRun] = handlerInterval;
      const secondsSinceLastRun = lastRun ? ((new Date()).getTime() - lastRun.getTime()) / 1_000 : null;
      const effectiveInterval = interval === undefined ? this.interval : interval; // If run interval was specified with a value, use that interval, otherwise default to global this.interval value

      if (!handler) { // In case somebody forgot to do basic housekeeping and removeOnTick
        this.debugMessage('Ticker.tick.badHandler');
        return;
      }

      // If the handler has never been called, or it has been at least "effectiveInterval" seconds then run handler
      this.debugMessage('Seconds since last run ' + (secondsSinceLastRun === null ? 'never' : secondsSinceLastRun) + ' > ' + effectiveInterval);
      if (secondsSinceLastRun === null || (secondsSinceLastRun * 1_000) >= effectiveInterval) {
        handler(lastRun);
        // Update "last run"
        this.onTickHandlerTuples[ix] = [handler, interval, new Date()];
      }
    });
  }

  /**
   * Add "tick" handler
   * @param {OnTickHandler} handler
   * @param {number?} minInterval If specified, handler will not be called unless at least this amount of time has passed
   */
  public onTick(handler: OnTickHandler, minInterval?: number): void {
    this.onTickHandlerTuples.push([handler, minInterval]);
  }

  /**
   * Remove "tick" listener
   * @param handler
   */
  public onRemoveTick(handler: OnTickHandler): void
  {
    this.onTickHandlerTuples = this.onTickHandlerTuples.filter(testHandlerTuple => testHandlerTuple[0] !== handler);
  }


  /**
   * Send debug message to listeners
   * @param msg
   * @private
   */
  private debugMessage(msg: string) : void{
    this.onDebugMessageHandlers.forEach(handler => handler(msg));
  }

  /**
   * Add debug message listener
   * @param handler
   */
  public onDebugMessage(handler: OnDebugMessageHandler): void {
    this.onDebugMessageHandlers.push(handler);
  }

  /**
   * Remove debug message listener
   * @param handler
   */
  public removeOnDebugMessage(handler: OnDebugMessageHandler): void {
    this.onDebugMessageHandlers = this.onDebugMessageHandlers.filter(testHandler => testHandler !== handler);
  }
}

export default Ticker;

