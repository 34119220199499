import React, {FunctionComponent} from 'react';
import './LinkTypeIcon.css';
import classNames from 'classnames';
import {LinkTypeServiceFactory} from '../../services/link-type-service';

export enum IconSize {
  Large = 'large',
  Normal = 'normal',
  Small = 'small'
}

type LinkTypeIconProps = {
  type: string
  subtype?: string
  size?: IconSize
};

const LinkTypeIcon: FunctionComponent<LinkTypeIconProps> = ({size, type, subtype}) => {
  const service = LinkTypeServiceFactory.create();

  const linkType = service.linkType(type, subtype);
  const src = linkType?.icon ?? service.default.icon;

  // switch(type) {
  //   case 'facebook':
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-facebook.svg';
  //     break;
  //   case 'instagram':
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-instagram.svg';
  //     break;
  //   case 'linkedin':
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-linkedin.svg';
  //     break;
  //   case 'pinterest':
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-pinterest.svg';
  //     break;
  //   case 'tiktok':
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-tiktok.svg';
  //     break;
  //   case 'twitter':
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-twitter.svg';
  //     break;
  //   case 'whatsapp':
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-whatsapp.svg';
  //     break;
  //   case 'youtube':
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-youtube.svg';
  //     break;
  //   case 'email':
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-email-30.png';
  //     break;
  //   default:
  //     src = process.env.PUBLIC_URL + '/assets/img/icons8-link-64.png';
  // }

  return <img src={src} alt={'Link icon: ' + type} className={classNames('LinkTypeIcon', size)}/>
}

export default LinkTypeIcon;
