import {createContext} from 'react';
import AppContextType from '../types/app-context-type';

const AppContext = createContext<AppContextType>({
  authUser: null,
  setAuthUser: () => {},
  onAuthUserChanged: (handler) => {},
  removeOnAuthUserChanged: (handler) => {}
})

export default AppContext;
