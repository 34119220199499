import React, {FunctionComponent, useContext, useState} from 'react';
import MicrositePageContainer from '../../components/page/MicrositePageContainer';
import MicrositeContext from '../../contexts/microsite-context';
import AppContext from '../../contexts/app-context';
// import QuickAuthUser from '../../components/auth/QuickAuthUser';
import SubscribablesList from '../../components/microsite/SubscribablesList';
import ErrorList from '../../components/common/ErrorList';
import Button, {ButtonType} from '../../components/common/Button';
import MicrositeView from '../../components/microsite/MicrositeView';
import Padded from '../../components/common/Padded';
import ButtonSet from '../../components/common/ButtonSet';
import {useNavigate} from 'react-router-dom';
import {LogoSize} from '../../components/page/PageHeader';
import {listTypeLabels} from '../../constants/list-type-labels';

enum SyncState {
  None,
  Syncing,
  Done,
  Error
}

const SubscribePage: FunctionComponent = () => {
  const {microsite, service: micrositeService} = useContext(MicrositeContext);
  const {authUser/*, setAuthUser*/} = useContext(AppContext);
  const [syncState, setSyncState] = useState<SyncState>(SyncState.None);

  const [showSelectedLists, setShowSelectedLists] = useState<boolean>(false);
  const [selectedIxes, setSelectedIxes] = useState<Array<number>>(
    microsite.lists
      .map((list, ix) => ix)
      .filter(ix => {
        if (authUser === null) return false;
        return micrositeService.isSubscribed(authUser, microsite.lists[ix].id);
      })
  );

  const toggleAtIndex = (ix: number) => {
    const existingIx = selectedIxes.indexOf(ix);
    let newIxes = [];
    if (existingIx >= 0) {
      newIxes = selectedIxes.filter((_, tIx) => tIx !== existingIx);
    } else {
      newIxes = [...selectedIxes, ix];
    }

    // Synchronize subscription for user
    if (authUser) {
      const subscribeListIds = newIxes.map(ix => microsite.lists[ix].id);
      setSyncState(SyncState.Syncing);
        micrositeService.synchronizeSubscription(authUser, microsite.slug, subscribeListIds)
          .then(() => {
            setSyncState(SyncState.Done);
          })
          .catch(e => {
            setSelectedIxes(selectedIxes); // Restore
            setSyncState(SyncState.Error);
          });
    }
    setSelectedIxes(newIxes);
  };

  const nav = useNavigate();

  return (
    <MicrositePageContainer padded={false} logoSize={LogoSize.Small}>
      {showSelectedLists && (
        <div className="txt-center m-t">
          <ButtonSet>
            <Button type={ButtonType.Secondary} onClick={() => setShowSelectedLists(false)}>
              &laquo; Back to Selection
            </Button>
            {authUser !== null && micrositeService.isMicrositeManager(authUser, microsite.id) && (
              <Button type={ButtonType.Primary}
                      onClick={() => nav('/manage')}
              >Manage</Button>
            )}
          </ButtonSet>
        </div>
      )}
      {/*<p style={{textAlign: 'center', fontSize: '2em', margin: 0}}>{microsite.name}</p>*/}

      {showSelectedLists && (
          <MicrositeView microsite={microsite}
                         listIxes={selectedIxes}
                         />
          // <SubscribedLists lists={selectedIxes.map(ix => microsite.lists[ix])}/>
      )}

      {!showSelectedLists && (
        <Padded>
          <h1 className="txt-center" style={{fontWeight: 'normal'}}>
            {microsite.name}
          </h1>
          <p className="txt-center">Select {listTypeLabels.movie.plural.toLowerCase()} to follow: </p>
          {syncState === SyncState.Error && (
            <ErrorList errors={['There was an error synchronizing your subscriptions.  Please try again.']} />
          )}
          {microsite.lists.length === 0 && <p>There are not currently any lists associated with this link.</p>}

          <SubscribablesList lists={microsite.lists}
                             selected={selectedIxes}
                             toggleAtIndex={ix => toggleAtIndex(ix)}
          />

          {selectedIxes.length === 0 ? (
            null /*<p>Select at least one to continue.</p>*/
          ) : (
            <div className="txt-center">
              <p>You have selected ({selectedIxes.length}) {selectedIxes.length === 1 ? listTypeLabels.movie.singular : listTypeLabels.movie.plural}.</p>
              {/*authUser === null && (
                <QuickAuthUser onAuth={authUser => {
                  setAuthUser(authUser);
                  setShowSelectedLists(true);
                  const listIds = selectedIxes.map(ix => microsite.lists[ix].id);
                  selectedIxes.forEach(ix => {
                    micrositeService.synchronizeSubscription(authUser, microsite.slug, listIds);
                  });
                }}
                               onSkip={() => {
                                 setShowSelectedLists(true);
                               }}
                />
              )*/}

              {/*authUser !== null && (
                <Button type={ButtonType.Primary} onClick={() => {
                  setShowSelectedLists(true);
                }}>
                  View Links
                </Button>
              )*/}
              <Button type={ButtonType.Primary} onClick={() => {
                setShowSelectedLists(true);
              }}>
                Show {listTypeLabels.movie.singular} Links
              </Button>
            </div>
          )}
        </Padded>
      )}
    </MicrositePageContainer>
  );
}

export default SubscribePage;
