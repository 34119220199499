import {FunctionComponent} from 'react';
import MicrositeType from '../../types/microsite-type';
import './VisitMicrositeList.css';
import ListView from '../common/ListView';
import Button, {ButtonType} from '../common/Button';
import ButtonSet from '../common/ButtonSet';

type VisitMicrositeListProps = {
  microsites: Array<MicrositeType>;
  onClickAtIndex: (ix: number) => void;
}

const AccountMicrositeList: FunctionComponent<VisitMicrositeListProps> = ({microsites, onClickAtIndex}) => {
  return (
    <div className="VisitMicrositeList">
      <ListView>
        {microsites.map((microsite, ix) => (
          <ListView.Item key={microsite.slug} onClick={() => onClickAtIndex(ix)}>
            <div className="flex">
              <div className="flex-grow">
                <div className="VisitMicrositeList__item__name">{microsite.name}</div>
                <div className="VisitMicrositeList__item__url">{microsite.url}</div>
              </div>
              <div className="">
                <ButtonSet>
                  <Button type={ButtonType.Secondary}>Visit</Button>
                </ButtonSet>
              </div>
            </div>
          </ListView.Item>
        ))}
      </ListView>
    </div>
  );
}

export default AccountMicrositeList;
