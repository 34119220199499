import {KeyboardEvent, FunctionComponent, useState} from 'react';
import './TextInput.css';
import classNames from 'classnames';

type TextInputProps = {
  disabled?: boolean
  error?: boolean
  value: string
  onChanged: (value: string) => void
  onKeyUp?: (ev: KeyboardEvent<HTMLInputElement>) => void
  placeholder?: string
};

const TextInput: FunctionComponent<TextInputProps> = (
  {
    disabled,
    error,
    value,
    onChanged,
    onKeyUp,
    placeholder
  }
) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  return (
    <div className={classNames('TextInput__wrap', {
      'TextInput--focused': isFocused || value.length > 0,
      'TextInput--placeholder': placeholder !== undefined
    })}>
      <input type="text"
             disabled={disabled}
             value={value}
             onChange={e => {
               if (disabled) return;
               onChanged(e.target.value);
             }}
             onKeyDown={ev => {
               if (onKeyUp) onKeyUp(ev);
             }}
             className={classNames('TextInput', {
               'TextInput--error': error === true
             })}
             placeholder={isFocused ? undefined : placeholder}
             onFocus={() => setIsFocused(true)}
             onBlur={() => setIsFocused(false)}
      />
      {placeholder && <div className="TextInput__label">{placeholder}</div>}
    </div>
  );
}

export default TextInput;
