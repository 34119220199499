import React, {Component} from 'react';
import './App.css';
import AuthUser from './models/auth-user';
import AppContext from './contexts/app-context';
import api from './services/api';
import LoadingScreen from './pages/LoadingScreen';
import MicrositeApp from './sites/microsite/MicrositeApp';
import AccountIndex from './sites/account/AccountIndex';
import DelayedTasker from './utils/DelayedTasker';
import classNames from 'classnames';
import {OnAuthUserChangedHandler} from './types/app-context-type';
import HandleHandoff from './pages/HandleHandoff';
import Ticker from './services/ticker';
import QuickAuthUser from './components/auth/QuickAuthUser';
import Padded from './components/common/Padded';

type AppProps = {
  slug?: string
}

type AppState = {
  authUser: AuthUser | null | undefined
  userLoggedOut: boolean // If the server indicates that the user is no longer valid, trigger this to smoothly handle logging back in
  handoff: string | null // A string passed to the app when the user is being transitioned from another domain
}

class App extends Component<AppProps, AppState> {
  // const nav = useNavigate();
  private onAuthChangedHandlers: Array<OnAuthUserChangedHandler> = [];
  private ticker: Ticker;

  constructor(props: Readonly<AppProps> | AppProps) {
    const matchHandoff = window.location.href.match(/handoff\/(.+)$/); // Step 1... remove handoff code from URL
    let handoff: string | null = null;
    if (matchHandoff) {
      handoff = matchHandoff[1];
    }
    super(props);
    this.state = {
      authUser: undefined,
      handoff,
      userLoggedOut: false
    }
    this.ticker = new Ticker();


    this.ticker.onTick(this.handleUserCheck.bind(this), 10 * 60 * 1_000); // 10 x 60 x 1,000 = 10 Minutes
  }

  public render() {
    if (this.state.handoff !== null) {
      return <HandleHandoff code={this.state.handoff}
                            onAuthUser={authUser => {
                              this.setState({handoff: null});
                              this.setAuthUser(authUser);
                            }}
      />
    }

    return (
      <div className="App">
        {this.state.authUser !== undefined && (
          <AppContext.Provider value={{
            authUser: this.state.authUser,
            setAuthUser: this.setAuthUser.bind(this),
            onAuthUserChanged: (handler) => {
              this.onAuthChangedHandlers.push(handler);
            },
            removeOnAuthUserChanged: (handler) => {
              this.onAuthChangedHandlers = this.onAuthChangedHandlers.filter(testHandler => testHandler !== handler);
            }
          }}>
            {this.props.slug === undefined ? <AccountIndex/> : <MicrositeApp slug={this.props.slug}/>}
          </AppContext.Provider>
        )}
        <div className={classNames('App__loading', {'App__loading--hide': this.state.authUser !== undefined})}>
          <LoadingScreen animate={true}/>
        </div>
        {this.state.userLoggedOut && (
          <div className="App__user_expired_modal">
            <main className="txt-center">
              <Padded>
                <p>You have been logged out for inactivity.</p>
                <QuickAuthUser authLabel="Log in"
                               onAuth={authUser => {
                                 this.setAuthUser(authUser);
                               }}
                               onSkip={() => {
                                 this.setAuthUser(null);
                               }}
                               skipLabel="Remain Anonymous"
                />
              </Padded>
            </main>
          </div>
        )}
      </div>
    );
  }

  public handleUserCheck() {
    if (!this.state.userLoggedOut && this.state.authUser !== undefined && this.state.authUser !== null) {
      api().auth.renew(this.state.authUser.authKey).then(authUser => {
        if (this.state.authUser?.authKey !== authUser?.authKey) {
          this.setState({userLoggedOut: true}); // Trigger userLoggedOut state
        }
      });
    }
  }

  // /**
  //  * AuthUser
  //  *   UNDEFINED = Not initialized
  //  *   NULL = Not logged in
  //  *   AuthUser = Logged in user
  //  */
  // // const [authUser, setAuthUserState] = useState<AuthUser | null | undefined>();
  // const setAuthUser = (authUser: AuthUser | null) => {
  //   if (authUser === null) {
  //     localStorage.removeItem('auth.key');
  //     localStorage.removeItem('auth.name');
  //   } else {
  //     localStorage.setItem('auth.key', authUser?.authKey!);
  //     localStorage.setItem('auth.name', authUser?.name!);
  //   }
  //   setAuthUserState(authUser);
  // }
  //
  // Initialize user
  componentDidMount() {
    this.ticker.start();
    /**
     * Check if we are receiving an API authorization transfer from a /handoff/[handoff-code]
     * If so:
     * 1. Remove code from URL and store in localStorage
     * 2. Retrieve code from localStorage and process
     */
    if (this.state.handoff !== null) {
      return;
    }

    let authKey: string | null = null;

    const delayedStart = 1; // How long to wait for loading screen to disappear after startup

    authKey = localStorage.getItem('auth.key');

    // Check existing user
    if (authKey !== null) {
      const delayedTasker = new DelayedTasker();
      api().auth.status(authKey).then(authUser => {
        delayedTasker.doAfter(delayedStart * 1000, () => {
          this.setAuthUser(authUser);
          localStorage.removeItem('handoff'); // Make sure not to leave handoff around
        });
      }).catch(e => {
        this.setAuthUser(null);
      });
      return;
    }
    // Setup user
    setTimeout(() => {
      this.setAuthUser(null); // User not logged in
    }, delayedStart * 1000);
  }; //, [authUser, nav]);

  componentWillUnmount() {
    this.ticker.stop();
  }

  private setAuthUser(authUser: AuthUser | null) {
    if (authUser === null) {
      localStorage.removeItem('auth.key');
      localStorage.removeItem('auth.name');
    } else {
      localStorage.setItem('auth.key', authUser?.authKey!);
      localStorage.setItem('auth.name', authUser?.name!);
    }
    this.setState({
      authUser,
      userLoggedOut: false
    });
    this.onAuthChangedHandlers.forEach(handler => handler(authUser)); // Let listeners know that auth has changed
  }
}

export default App;
