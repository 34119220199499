import APIClient from '../api-client';
import AuthUser from '../../models/auth-user';
import {AxiosRequestConfig} from 'axios';

// import AuthUser from '../../models/auth-user';
// import {AxiosRequestConfig, AxiosRequestHeaders} from 'axios';

// type AxiosAuthorizationConfig<D> = AxiosRequestConfig<D> & {
//   headers: AxiosRequestHeaders
// }

class BaseApi {
  constructor(protected apiClient: APIClient) {
  }

  config(config?: AxiosRequestConfig): AxiosRequestConfig {
    if (!config) config = {} as AxiosRequestConfig;
    if (!config.headers) config.headers = {};
    config.headers["X-Domain"] = window.location.hostname;

    return config;
  }

  setName(authUser: AuthUser, name: string): Promise<void> {
    return this.apiClient.put<void, {name: string}>('/auth/name', {name}, this.authConfig(authUser)).then(response => {});
  }

  authConfig(authUser: AuthUser, config?: AxiosRequestConfig): AxiosRequestConfig {
    return this.authKeyConfig(authUser.authKey, config);
  }

  authKeyConfig(authKey: string, config?: AxiosRequestConfig): AxiosRequestConfig {
    config = this.config(config);
    config.headers!['X-Auth-Key'] = authKey;
    return config;
  }
  //
  // createDefaultConfigWithAuthorization<D = any>(authUser?: AuthUser): AxiosRequestConfig<D> {
  //   const config: AxiosRequestConfig<D> = {
  //     headers: {
  //       Authorization: this.createAuthorizationHeader(authUser)
  //     }
  //   }
  //
  //   return config;
  // }
  //
  // createAuthorizationHeader(authUser?: AuthUser): string {
  //   if (!authUser) return '';
  //
  //   return 'AuthKey ' + authUser.authKey;
  // }
}

export default BaseApi;
