import BaseApi from './base-api';
import MicrositeType from '../../types/microsite-type';
import AuthUser from '../../models/auth-user';
import LinkListResponseType from '../../types/link-list-response-type';
import SuccessOrFailResponseType from '../../types/success-or-fail-response-type';

type RegisterMicrositeRequest = {
  slug: string
  name: string
}

type SaveMicrositeResponse = {
  success: boolean
  errors: Array<string>
  microsite: MicrositeType
}

type MicrositesResponse = {
  microsites: Array<MicrositeType>
  managedMicrosites: Array<number>
  managedLists: Array<number>
}

type MicrositeResponse = {
  microsite: MicrositeType
  managedMicrosites: Array<number>
  managedLists: Array<number>
  subscribedLists: Array<number>
}

class MicrositeApi extends BaseApi {
  public getMicrosites(authUser: AuthUser): Promise<MicrositesResponse> {
    return this.apiClient.get<MicrositesResponse>('/microsites', this.authConfig(authUser)).then(response => {
      return response.data;
    });
  }

  public getMicrosite(slug: string, authUser: (AuthUser | null) = null): Promise<MicrositeResponse> {
    const config = authUser === null ? this.config() : this.authConfig(authUser);
    return this.apiClient.get<MicrositeResponse>('/microsites/' + slug, config).then(response => {
      return response.data;
    });
  }

  public getLists(slug: string, authUser: (AuthUser | null) = null): Promise<MicrositeType> {
    const config = authUser === null ? this.config() : this.authConfig(authUser);
    return this.apiClient.get<MicrositeType>('/microsites/' + slug + '/lists', config).then(response => {
      return response.data;
    });
  }

  public create(authUser: AuthUser, slug: string, name: string): Promise<SaveMicrositeResponse> {
    const config = this.authConfig(authUser);

    return this.apiClient.post<SaveMicrositeResponse, RegisterMicrositeRequest>('/microsites', {
      slug,
      name
    }, config)
      .then(response => response.data);
  }

  public save(authUser: AuthUser, microsite: MicrositeType): Promise<SaveMicrositeResponse> {
    const {slug, name} = microsite;

    return this.apiClient
      .put<SaveMicrositeResponse, {name: string}>(
        '/microsites/' + slug,
        {name},
        this.authConfig(authUser)
      ).then(response => response.data);
  }

  public synchronizeSubscription(authUser: AuthUser, slug: string, lists: Array<number>): Promise<void> {
    return this.apiClient.put<void, { lists: Array<number> }>(
      '/microsites/' + slug + '/lists/subscribe/synchronize',
      {
        lists
      },
      this.authConfig(authUser)
    ).then(response => response.data);
  }

  public associateList(authUser: AuthUser, slug: string, listId: number): Promise<LinkListResponseType> {
    return this.apiClient.put<LinkListResponseType>('/microsites/' + slug + '/lists/' + listId + '/associate', undefined, this.authConfig(authUser))
      .then(response => response.data);
  }

  public disassociateList(authUser: AuthUser, slug: string, listId: number): Promise<SuccessOrFailResponseType> {
    return this.apiClient.delete<LinkListResponseType>('/microsites/' + slug + '/lists/' + listId + '/associate', this.authConfig(authUser))
        .then(response => response.data);
  }

  public archiveList(authUser: AuthUser, slug: string, listId: number): Promise<SuccessOrFailResponseType> {
    return this.apiClient.put<LinkListResponseType, {}>('/microsites/' + slug + '/lists/' + listId + '/archive', {}, this.authConfig(authUser))
        .then(response => response.data);
  }

  public voteForList(slug: string, listId: number): Promise<SuccessOrFailResponseType> {
    return this.apiClient.post<SuccessOrFailResponseType>('/microsites/' + slug + '/lists/' + listId + '/vote').then(response => response.data);
  }
}

export default MicrositeApi;
