import {FunctionComponent} from 'react';
import './ActivityIndicator.css';
import spinner from '../../assets/img/Dual Ring-1.3s-200px.svg';

const ActivityIndicator: FunctionComponent = () => {
  return (
    <div className="ActivityIndicator">
      <img src={spinner} style={{width: 30}} alt="Activity Indicator"/>
    </div>
  );
}

export default ActivityIndicator;
